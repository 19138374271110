.interpreter {
    height: 90vh;
    background-image: radial-gradient(circle at 0 0, $dark-clay, $dark);
    padding: 50px;
    display: flex;
    &__invoice-data {
        flex-basis: 300px;
        flex-grow: 1;
        padding-right: 2rem;
    }
    &__img-wrapper {
        overflow: auto;
        flex-basis: 760px;
        flex-grow: 0;
        display: block;
        margin: auto;
        height: 100%;
        position: relative;
        padding-right: 1.25rem;
        &:active {
            cursor: grabbing;
        }
        &--loading {
            overflow: hidden;
        }
    }
    &__hits-wrapper {
        position: relative;
    }
    &__hit-item {
        transition: all 0.125s ease;
        position: absolute;
        border: 4px solid lighten($blue, 20%);
        padding: 1px;
        box-sizing: content-box;
        animation: expand 0.5s ease forwards;
        &--active {
            border-color: darken($saffron, 20%);
        }
    }
    &__img {
        display: block;
        max-width: 100%;
        object-fit: contain;
        transform-origin: center center;
    }
    &__zoom {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        background-image: linear-gradient(to bottom, $charcoal-grey, $dark-clay);
        box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.4);
        display: inline-block;
        width: 350px;
        max-width: 100%;
        padding: 20px;
    }
    &__zoom-controls {
        display: flex;
        .input-range {
            margin: 0 12px;
        }
    }
    &__zoom-percent {
        font-size: 14px;
        font-weight: 600;
        text-align: center;
    }
    // .button--chip {
    //     position: absolute;
    //     right: 10px;
    //     bottom: -10px;
    // }
    .data-list {
        height: 100%;
        overflow: auto;
        padding: 0 1rem;
    }
    ::-webkit-scrollbar-track {
        width: 0.5rem;
        background-color: $scrollTrack;
    }
    ::-webkit-scrollbar {
        width: 0.5rem;
        background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {
        width: 0.25rem;
        height: 2.5625rem;
        border-radius: 0.25rem;
        border: 0.25rem solid $scroll;
        background-color: $scroll;
    }
    .form,
    .input {
        margin-bottom: 1rem;
    }
    .form .button--function {
        margin-bottom: 0;
    }
}
@media all and (max-width: 1279px) {
    .interpreter {
        padding: 36px 26px;
        .data-list {
            padding: 0 0.75rem 0 0;
            margin-right: 0.75rem;
        }
        .data-list__item {
            padding: 0;
        }
        &__invoice-data {
            padding: 0;
        }
    }
}
@media all and (max-width: 1079px) {
    .interpreter {
        &__img-wrapper {
            display: none;
        }
    }
}
@media all and (max-width: 768px) {
    .interpreter {
        padding: 1rem;
        .data-list {
            height: 90%;
            &__item {
                min-width: 0;
                padding: 0;
            }
        }
        &__img-wrapper {
            display: none;
        }
    }
}
