// Prevent OSX "overscroll"
* {
    box-sizing: border-box;
}
html {
    overflow: hidden;
}
body {
    overflow: auto;
    background-color: $night;
    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: column;
}

// Use 100% of page, always
html,
body {
    width: 100%;
    height: 100%;
}

#root {
    overflow: visible;
    flex-basis: 100%;
}

::-webkit-scrollbar-track {
    width: 0.5rem;
    height: 0.5rem;
    background-color: $scrollTrack;
}
::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
    background-color: transparent;
}
::-webkit-scrollbar-thumb {
    width: 0.25rem;
    height: 2.5625rem;
    border-radius: 0.25rem;
    border: 0.25rem solid $scroll;
    background-color: $scroll;
}

.content__wrapper {
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
}

.content__menu {
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
}

.link,
a {
    transition: all 0.125s ease;
    &:link,
    &:visited,
    &:active {
        color: white;
        font-weight: 600;
        text-decoration: none;
    }
    &:hover {
        color: white;
    }
    &:active {
        color: white;
    }
}

@media only screen and (orientation: portrait) {
    body {
        .navigation__menu {
            height: calc(100% - env(safe-area-inset-top) - env(safe-area-inset-bottom)) !important;
        }
        .modal__content {
            height: calc(100% - env(safe-area-inset-top) - env(safe-area-inset-bottom)) !important;
        }

        .navigation {
            padding-top: calc(0px + env(safe-area-inset-top));
            padding-bottom: calc(0px + env(safe-area-inset-bottom));
        }
    }
}
