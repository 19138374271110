.input-range {
    appearance: none;
    width: 100%;
    margin: 2px 0 1rem;
    background: none;
    &:focus {
        outline: none;
    }
    &::-webkit-slider-thumb {
        @include thumbStyles();
        -webkit-appearance: none;
        margin-top: -4px;
    }
    &::-moz-range-thumb {
        @include thumbStyles();
    }
    &::-ms-thumb {
        @include thumbStyles();
        margin-top: 0;
    }
    &::-webkit-slider-runnable-track {
        @include trackLowerStyles();
    }
    &:focus::-webkit-slider-runnable-track {
        background-image: linear-gradient(to right, lighten($meadow, 5%), lighten($tealish, 5%));
    }
    &::-moz-range-track {
        @include trackUpperStyles();
    }
    &::-moz-range-progress {
        @include trackLowerStyles();
    }
    &::-moz-focus-outer {
        border: 0;
    }
    &:focus::-moz-range-progress {
        background-image: linear-gradient(to right, lighten($meadow, 5%), lighten($tealish, 5%));
    }
    &::-ms-track {
        width: 100%;
        height: 8px;
        cursor: pointer;
        background: transparent;
        border-color: transparent;
        color: transparent;
    }
    &::-ms-fill-lower {
        @include trackLowerStyles();
        border-radius: 8px;
    }
    &::-ms-fill-upper {
        @include trackUpperStyles();
        border-radius: 8px;
    }
    &:focus {
        &::-ms-fill-lower {
            background-image: linear-gradient(to right, lighten($meadow, 5%), lighten($tealish, 5%));
        }
    }

    &--big {
        &::-webkit-slider-thumb {
            width: 32px;
            height: 32px;
            margin-top: -8px;
        }
        &::-moz-range-thumb {
            width: 32px;
            height: 32px;
        }
        &::-ms-thumb {
            width: 32px;
            height: 32px;
            margin-top: 0;
        }
        &::-webkit-slider-runnable-track {
            height: 16px;
            border-radius: 8px;
        }
        &::-moz-range-track {
            height: 16px;
            border-radius: 8px;
        }
        &::-moz-range-progress {
            height: 16px;
            border-radius: 8px;
        }
        &::-ms-track {
            height: 32px;
            border-radius: 8px;
        }
        &::-ms-fill-lower {
            height: 16px;
            border-radius: 16px;
        }
        &::-ms-fill-upper {
            height: 16px;
            border-radius: 16px;
        }
    }
}
