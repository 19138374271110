.sell-progress {
    display: flex;
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 2rem;
    position: relative;
    &__item {
        flex-grow: 1;
        text-align: center;
        padding: 1rem;
    }

    .caption {
        font-style: normal;
        display: inline-block;
    }
    .icon {
        margin-right: 8px;
    }
    &__bar,
    &:before {
        content: '';
        position: absolute;
        bottom: -1px;
        height: 1px;
        width: 100%;
    }
    &:before {
        background: $slate;
    }
    &__bar {
        @include trackLowerStyles();
        width: 2%;
        transition: width 0.25s ease-in-out;
        height: 1px;
    }
}

@media all and (max-width: 1080px) {
    .sell-progress {
        flex-direction: column;
        &__item {
            text-align: left;
            padding: 18px 20px 18px 30px;
            display: flex;
            align-items: center;
            &:first-child {
                padding-top: 6px;
            }
            &--last {
                padding-bottom: 6px;
            }
        }
        &__bar,
        &:before {
            content: '';
            position: absolute;
            bottom: auto;
            top: 0;
            height: 100%;
            width: 1px;
        }
        &:before {
            background: $slate;
        }
        &__bar {
            @include trackLowerStyles();
            width: 1px;
            transition: height 0.25s ease-in-out;
            height: 2%;
        }
    }
}
