.settings {
    overflow-x: hidden;
    .button {
        margin-bottom: 0;
        margin-left: 0.5rem;
    }
    .input {
        animation: appear 0.5s 0.125s ease forwards;
        opacity: 0;
        margin-right: 1rem;
    }
    &__detail {
        margin-bottom: 2rem;
        min-height: 16.25rem;
    }
    &__content-wrapper {
        display: none;
        transform: scaleY(0);
        animation: expand 0.25s ease forwards;
        animation-delay: 0;
        transform-origin: top;
    }
    &__item-content {
        width: 100%;
        padding: 1rem 2.0625rem;
        opacity: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        animation: appear 0.25s ease forwards;
        animation-delay: 0.25s;
    }
    .content__wrapper--stuffing {
        opacity: 0;
        animation: appear 0.5s 0.25s ease forwards;
    }
    &__list {
        animation: appear 0.5s 0.125s ease forwards;
        opacity: 0;
        list-style-type: none;
        padding: 0;
        margin: 0;
        margin-bottom: 3rem;
    }
    &__item {
        border-bottom: 1px solid $divider;
        transition: 0.125s background ease;
        &--active {
            box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.3);
            .settings__item-row {
                background: $charcoal-grey;
            }
            .settings__content-wrapper {
                background-image: linear-gradient(to bottom, $charcoal-grey, $dark-clay);
                display: flex;
                animation-delay: 0;
            }
            .settings__content {
                animation-delay: 0.125s;
            }
            .settings__arrow-img {
                transform: rotate(180deg);
            }
            border-color: transparent;
        }
    }
    &__editable,
    &__user-name {
        display: flex;
        animation: appear 0.5s 0.125s ease forwards;
        opacity: 0;
        .paragraph {
            margin-bottom: 0.5rem;
        }
        &--actions {
            animation: appear 0.5s 0.125s ease forwards;
            opacity: 0;
            display: flex;
            flex-direction: row-reverse;
            .button {
                animation: appear 0.5s 0.125s ease forwards;
                opacity: 0;
            }
        }
    }
    &__panel {
        flex-basis: 50%;
        min-width: 11rem;
        max-width: 100%;
        flex-grow: 1;
        position: relative;
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;
        &--switch {
            flex-basis: 25%;
            width: 2px;
        }
    }
    &__item-row {
        padding: 0.5rem 3.625rem 0.5rem 4.5rem;
        transition: background 0.25s ease, padding 0.125s ease;
        width: 100%;
        position: relative;
        display: flex;
        min-height: 3.75rem;
        cursor: pointer;
        user-select: none;
        &--button {
            padding-right: 1.3125rem;
            cursor: default;
        }
        &--switch {
            cursor: default;
        }
        .spinner {
            height: 1rem;
            width: 1rem;
            margin: auto;
            margin-left: 0.5rem;
        }
    }
    &__arrow-img,
    &__item-button {
        display: inline-flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 0.25rem 0;
        .caption {
            margin-bottom: 0;
        }
    }
    &__arrow-img {
        margin-bottom: 0;
    }
    &__item-arrow {
        padding: 0 1.3125rem;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        right: 0;
    }
    &__arrow-img {
        width: 1rem;
        height: auto;
        transform: rotate(0);
        transition: transform 0.25s ease;
        &--hidden {
            pointer-events: none;
            visibility: hidden;
        }
    }
    .input__required-information {
        color: lighten($lipstick, 12%);
        font-weight: bold;
    }
    &__ask-notification {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2rem;
        .caption {
            margin: 0;
            align-self: center;
        }
        .button {
            animation: appear 0.5s 0.125s ease forwards;
            opacity: 0;
        }
    }
    &__email-integration {
        margin-bottom: 2rem;
    }
}

@media all and (max-width: 1400px) {
    .settings {
        &__panel {
            min-width: 8rem;
        }
    }
}

@media all and (max-width: 1230px) {
    .settings {
        &__panel {
            min-width: 4rem;
        }
    }
}

@media all and (max-width: 880px) {
    .settings {
        margin: 0 -1rem 3rem;
        width: 100%;
        &__editable {
            flex-flow: column;
        }
        &__item-content {
            grid-template-columns: repeat(3, 1fr);
            .invoice-list__panel {
                &:first-child {
                    grid-column-end: span 2;
                }
            }
        }
        &__item-row {
            padding: 1.25rem 2.3125rem 1.25rem 2.9375rem;
            flex-wrap: wrap;
            .spinner {
                height: 1rem;
                width: 1rem;
                margin: auto;
                margin-bottom: 1rem;
            }
        }
        &__item {
            &--active {
                .imports__content-wrapper {
                    flex-direction: column;
                }
            }
        }
    }
}
