.support-form {
    &__content {
        display: none;
        text-align: left;
        padding: 1.5rem;
        background: $slate;
        margin: 0.5rem 0;
        animation: slide-up 0.25s ease;
        position: relative;
    }
    &__info {
        margin-bottom: 1rem;
    }
    &__form {
        overflow: hidden;
    }
    &__button {
        margin-bottom: -1px;
        appearance: none;
        border: none;
        color: white;
        background-color: $slate;
        display: inline-block;
        padding: 8px 16px;
        box-shadow: 0 6px 10px 1px rgba(0, 0, 0, 0.2);
        font-size: 0.875rem;
        .icon,
        span {
            vertical-align: middle;
        }
        .icon {
            margin-left: 8px;
        }
    }
    .close {
        position: absolute;
    }
    .button--small {
        min-width: 100px;
        margin-bottom: 0;
    }
    &--active {
        .support-form__content {
            display: block;
        }
        .button {
            float: right;
            font-size: 0.875rem;
        }
    }
}
