@mixin thumbStyles() {
    width: 16px;
    height: 16px;
    background-color: #ffffff;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    border-radius: 50%;
    cursor: pointer;
    // &:after {
    //     content: '';
    //     width: 100vw;
    //     background: pink;
    //     height: 100%;
    //     position: absolute;
    // }
}

@mixin trackLowerStyles() {
    height: 8px;
    border-radius: 4px;
    box-shadow: 0 0 10px 0 rgba(23, 158, 90, 0.5);
    background-image: linear-gradient(to right, $meadow, $tealish);
    width: 100%;
    cursor: pointer;
    border: 0px solid #010101;
}

@mixin trackUpperStyles() {
    height: 8px;
    border-radius: 4px;
    width: 100%;
    cursor: pointer;
    background-color: $slate;
}
