.transfer-costs {
    position: relative;
    min-height: 80px;
    opacity: 0;
    animation: appear 0.5s ease forwards;
    &__item {
        appearance: none;
        border: none;
        background: transparent;
        width: 100%;
        text-align: left;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 15px 1rem 15px;
        transition: background 0.25s ease, box-shadow 0.25s ease;
        cursor: pointer;
        &:hover {
            background: rgba($slate, 0.5);
            outline: none;
        }
        &:focus {
            box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.4);
        }
        &--active {
            background-color: $charcoal-grey;
            box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.4);
        }
        &--wait {
            .transfer-costs__state {
                box-shadow: 0 0 10px 0 rgba($saffron, 0.3);
                background-color: $saffron;
            }
        }
        &--warning {
            .transfer-costs__state {
                box-shadow: 0 0 10px 0 rgba($lipstick, 0.5);
                background-color: $lipstick;
            }
        }
        &--large {
            padding: 0;
            margin-bottom: 1.5rem;
            cursor: default;
            &:hover,
            &:focus {
                background: none;
            }
            .transfer-costs__content {
                .title {
                    font-size: 22px;
                    font-weight: bold;
                }
            }
        }
    }
    &__content {
        position: relative;
        padding-left: 20px;
        .title {
            margin-bottom: 0;
        }
        .caption {
            font-style: normal;
        }
    }
    &__state {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 4px;
        height: 100%;
        border-radius: 2px;
        box-shadow: 0 0 10px 0 rgba($meadow, 0.5);
        background-color: $meadow;
    }
    &--no-spacing {
        margin: 0 0 1rem;
    }
    .icon--right {
        width: 20px;
        height: 20px;
    }
    .spinner {
        width: 30px;
        height: 30px;
        position: absolute;
        margin: auto;
    }
}
