.form {
    margin: 0 auto 2.0625rem auto;
    position: relative;

    &__async-status {
        background: radial-gradient(circle at 0 0, lighten($charcoal-grey, 2%), $charcoal-grey);
        box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.3);
        width: 100%;
        padding: 1.375rem;
        margin-bottom: 2.0625rem;
        position: relative;

        &--error {
            background: lighten($saffron, 0%);
            color: $textColorDark;
        }
    }
    &--dark {
        .input__required-information {
            color: lighten($lipstick, 12%);
            font-weight: bold;
        }
    }
    &--boundry {
        max-width: 500px;
        margin-left: initial;
        margin-right: initial;
    }
    .close {
        position: absolute;
        top: -12px;
        right: -12px;
    }
}
