.integration {
    .onboarding-button {
        position: relative;
        .spinner {
            right: 0;
            left: 0;
        }
    }
    .info-process {
        margin-block-start: 30px;
    }
    .selection-list {
        display: flex;
        column-gap: 60px;
        @media all and (max-width: 1300px) {
            display: block;
        }
    }
    .selected {
        box-shadow: inset 0 0 10px $blue !important;
    }
    .selection-content {
        height: 180px;
        flex: 1;
        @media all and (max-width: 1300px) {
            margin-bottom: 10px;
        }
        background: radial-gradient(circle at 0 0, lighten($charcoal-grey, 2%), $charcoal-grey);
        box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.3);
        cursor: pointer;
        display: flex;
        align-items: center;
        .content {
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 150px;
            .inner-content {
                max-width: 65%;
                align-items: center;
                text-align: center;
            }
        }
        .fortnox-logo {
            width: 45%;
            height: 45%;
        }
        .visma-logo {
            vertical-align: middle;
            margin-right: 10px;
        }
    }
    .iframe-container {
        overflow: hidden;
        padding-top: 56.25%;
        padding-bottom: 56.25%;
        iframe {
            border: 0;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }
    .content__main {
        display: flex;
        flex-direction: column;
        overflow: visible;
    }
    .content__menu {
        padding: 45px 34px 22px;
        flex-grow: 0;
        overflow: hidden;
        justify-content: center;
        align-items: center;
    }
    .content__wrapper {
        width: 100%;
        flex-basis: 100%;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }
}
