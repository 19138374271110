.filter {
    position: relative;
    &__greeting {
        margin-left: 22px;
        margin-right: 22px;
        margin-bottom: 2rem;
        position: relative;
        .title {
            margin-bottom: 8px;
        }
        .button {
            animation: fade-in 0.25s ease;
            position: absolute;
            bottom: -0.5rem;
            transform: translateY(100%);
            margin: initial;
            font-size: 14px;
        }
    }
    &__content {
        width: 100%;
        .data-list {
            animation: appear 0.25s ease forwards;
            opacity: 0;
        }
    }
    &__section {
        animation: fade-in 0.5s ease;
        border-bottom: 1px solid $divider;
        padding: 22px;
        &:last-child {
            border-bottom: none;
        }
    }
    &__footer {
        padding-top: 22px;
        width: 100%;
        align-self: flex-end;
        margin: 0 22px;
        position: relative;
        > .data-list {
            margin-bottom: 10px;
            position: static;
            &:before {
                content: '';
                position: absolute;
                top: -1px;
                left: 0;
                right: 0;
                background: rgba(white, 0.2);
                height: 1px;
                margin: auto;
            }
        }
    }
    &__footer-summary {
        box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
        background-color: $charcoal-grey;
        margin: 0 -32px;
        .summary-box {
            margin-bottom: 0;
            padding: 22px;
            .summary-box__content {
            }
            .data-list {
                width: 100%;
            }
            .data-list__item {
                margin-bottom: 0;
                text-align: right;
            }
            .data-list__data {
                font-size: 22px;
            }
        }
    }
    &__states {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 0 -5px;
        .button {
            flex-grow: 0;
            flex-shrink: 2;
            width: 45px;
            padding: 4px 0;
            margin: 0 5px 10px;
            transition: opacity 0.25s ease, box-shadow 0.25s ease;
            &:focus {
                outline: none;
            }
            .icon {
                transition: opacity 0.25s ease;
            }
        }
        .button--disabled {
            box-shadow: none;
            pointer-events: all;
            cursor: pointer;
            .icon {
                opacity: 0;
            }
        }
        &--advanced {
            .a {
                background: none;
                color: $gull;
                font-size: small;
                margin-left: auto;
                border: none;
                cursor: pointer;
            }
        }
        &--select {
            margin-top: 0.5rem;
            width: 100%;
        }
    }
    &__specific-state {
        text-align: center;
        .button--disabled {
            box-shadow: none;
            pointer-events: all;
            cursor: pointer;
            .icon {
                opacity: 0;
            }
        }
        .button {
            width: 100%;
        }
    }
    &__month-toggler {
        opacity: 0;
        animation: slide-up 0.5s 0.125s ease forwards;
        position: sticky;
        top: -40px; // Negates the padding-top of .content__menu
        display: flex;
        z-index: 1;
        justify-content: space-between;
        box-shadow: 0 0 1.875rem 0 rgba(0, 0, 0, 0.4);
        background-color: $slate;
        align-items: center;
        padding: 12px 12px;
        margin-bottom: 0.75rem;
        .title {
            margin-bottom: 0;
            text-transform: capitalize;
        }
        .button--icon {
            margin-bottom: 0;
            &:hover,
            &:focus {
                background: rgba(white, 0.2);
                box-shadow: 0 0 25px 5px rgba(0, 0, 0, 0.3);
            }
        }
    }
    .data-list {
        &__item {
            min-width: 120px;
            margin-bottom: 1.625rem;
        }
    }
    .spinner {
        position: relative;
        z-index: 2;
        height: 1em;
        width: 1em;
        margin: auto;
        display: inline-block;
        margin-left: 0.5em;
        vertical-align: middle;
    }
    .DayPicker {
        width: 100%;
    }

    &--full-height {
        min-height: 100%;
        display: flex;
        flex-wrap: wrap;
    }
}

@media all and (max-width: 1080px) {
    .filter {
        &__greeting {
            padding-top: 1.5rem;
        }
    }
}
