.sell-invoice {
    &__empty {
        margin: auto;
        text-align: center;
        position: relative;
        .icon {
            height: auto;
            width: auto;
            max-width: 100%;
        }
        .button--function {
            display: none;
        }
    }
    &__async-status {
        background: radial-gradient(circle at 0 0, lighten($charcoal-grey, 2%), $charcoal-grey);
        box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.3);
        width: 100%;
        padding: 1.375rem;
        margin-top: 1rem;
        margin-bottom: 2.0625rem;
        position: relative;

        &--error {
            background: lighten($saffron, 0%);
            color: $textColorDark;
        }
    }
    &__upload-toggler {
        display: none;
    }
    &__credit-check {
        .table {
            animation: appear 0.25s 0.5s ease forwards;
            opacity: 0;
            .button--icon {
                margin-bottom: 0;
            }
        }
        .table__cell--icon {
            width: 3.1875rem;
            overflow: hidden;
            text-align: center;
            > .icon {
                width: 1.9375rem;
                opacity: 0;
                animation: slide-right 0.25s ease forwards;
            }
        }
        .table__row--clickable {
            &.files__item--active {
                box-shadow: initial;
                background: $message;
            }
        }
        .files__item--active:not(.table__row--clickable) {
            box-shadow: initial;
            .table__cell,
            .files__content-wrapper {
                padding: 0;
            }
        }
        .files__content-wrapper {
            background: $message;
            padding: 22px;
            .link {
                color: lighten($blue, 7.5%);
                &:link,
                &:visited,
                &:active {
                    color: lighten($blue, 7.5%);
                }
                &:hover {
                    color: lighten($blue, 15%);
                }
            }
        }
        .files__information {
            flex-direction: column;
        }
        .confirm-action {
            &:after {
                left: auto;
                right: 20px;
            }
        }
        .files__panel {
            .data-list {
                margin-bottom: 0;
            }
        }
    }
    .files {
        animation: appear 0.25s 0.5s ease forwards;
        opacity: 0;
    }
    .summary-box {
        animation: appear-up 0.25s 0.5s ease forwards;
        opacity: 0;
        &--alt {
            animation: appear-up 0.5s ease forwards;
        }
        .title--h2 {
            display: inline-block;
        }
        .spinner {
            position: static;
            height: 1.375rem;
            width: 1.375rem;
            vertical-align: sub;
            margin-left: 1rem;
        }
    }
    .content__main {
        display: flex;
        flex-direction: column;
        overflow: visible;
    }
    .content__menu {
        flex-grow: 0;
        overflow: hidden;
    }
    .content__wrapper {
        width: 100%;
        flex-basis: 90%;
        flex-grow: 2;
        display: flex;
        flex-direction: column;
    }
    .receipt:not(.receipt--small) {
        width: 100%;
        flex-basis: 10%;
        flex-grow: 1;
    }
    .data-list--small {
        margin-bottom: 0;
    }
    .submit-uploads {
        animation: appear 0.25s 0.5s ease forwards;
        opacity: 0;
    }
    .submit-sell-invoice {
        animation: appear 0.25s 0.5s ease forwards;
        opacity: 0;
    }
    &__caption {
        margin-left: 2rem;
        margin-right: 2rem;
    }
}

@media all and (max-width: 1080px) {
    .sell-invoice {
        .content__menu--inactive {
            display: none;
        }
        &__uploader {
            display: none;
            .upload-file__label {
                display: none;
                height: 0px;
            }
            .upload-file {
                height: 100%;
            }

            &--active {
                display: block;
            }
        }
        &__upload-toggler {
            display: flex;
            z-index: 4;
            color: $textColor;
            font-weight: bold;
            width: 100%;
            text-align: center;
            background: $slate;
            appearance: none;
            border: none;
            align-items: center;
            justify-content: center;
            .icon {
                width: 20px;
                transition: transform 0.25s ease;
                margin-left: 8px;
            }
            &--active {
                .icon {
                    transform: rotate(-180deg);
                }
            }
        }
        &__empty {
            .button--function {
                display: block;
                margin: auto;
            }
        }
    }
}
