.files {
    padding: 0;
    margin: 0 0 3rem;
    list-style: none;
    &__item {
        border-bottom: 1px solid $divider;
        transition: 0.125s background ease;
        &--active {
            box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.3);
            .files__item-row {
                background: $charcoal-grey;
            }
            .files__content-wrapper {
                background-image: linear-gradient(to bottom, $charcoal-grey, $dark-clay);
                display: flex;
                animation-delay: 0;
            }
            .files__content {
                animation-delay: 0.125s;
            }
            .files__arrow-img {
                transform: rotate(180deg);
            }
            border-color: transparent;
        }
    }
    &__content-wrapper {
        display: none;
        transform: scaleY(0);
        animation: expand 0.25s ease forwards;
        animation-delay: 0;
        transform-origin: top;
    }
    &__item-content {
        width: 100%;
        padding: 1rem 2.0625rem;
        opacity: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        animation: appear 0.25s ease forwards;
        animation-delay: 0.25s;
    }
    &__panel {
        flex-basis: 50%;
        min-width: 18.75rem;
        max-width: 100%;
        flex-grow: 1;
        position: relative;
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;
        &--small {
            margin-right: 2rem;
            flex-basis: 0%;
        }
        &--reverse {
            align-items: flex-end;
        }
        &-loading {
            margin-bottom: 2rem;
            .caption {
                line-height: 1;
                margin-bottom: 0;
            }
            .spinner {
                width: 1.1875rem;
                height: 1.1875rem;
                position: relative;
                display: inline-block;
                vertical-align: middle;
                margin-right: 1rem;
            }
        }
        .confirm-action {
            top: -20px;
            &:after {
                right: auto;
                left: 40px;
            }
        }
        .input {
            flex-grow: 0;
            margin-bottom: 0;
            opacity: 0;
            animation: slide-up 0.5s ease forwards;
        }
        &--full {
            flex-basis: 100%;
            display: block;
        }
        &--divider {
            width: 2px;
            height: 420px;
            margin: 13px 16px 25px;
            border: solid 1px $slate;
        }
        &--divider-horizontal {
            height: 2px;
            width: 335px;
            border: solid 1px $slate;
            margin-left: auto;
            margin-right: auto;
            margin-top: -2.6rem;
        }
    }
    &__information {
        display: flex;
        flex-wrap: wrap;
        padding-right: 2.0625rem;
    }
    &__item-row {
        padding: 0.5rem 3.625rem 0.5rem 4.5rem;
        transition: background 0.25s ease, padding 0.125s ease;
        width: 100%;
        position: relative;
        display: flex;
        min-height: 3.75rem;
        cursor: pointer;
        user-select: none;
        &--switch {
            cursor: default;
        }
        &--button {
            padding-right: 1.3125rem;
            cursor: default;
            .files__cloud-img {
                .icon {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }
    &__item-name,
    &__item-arrow,
    &__arrow-img,
    &__cloud-img,
    &__item-amount,
    &__item-button {
        display: inline-flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 0.25rem 0;
        .caption {
            margin-bottom: 0;
        }
    }
    &__arrow-img {
        margin-bottom: 0;
    }
    &__item-name {
        flex-grow: 1;
        flex-shrink: 2;
        padding-right: 0.625rem;
    }
    &__item-progress {
        flex-basis: 17.5rem;
        position: relative;
        display: block;
        margin: auto 0;
        .spinner {
            width: 2rem;
            height: 2rem;
            right: 0;
            left: auto;
            top: 0;
            bottom: 0;
        }
    }
    &__item-arrow {
        padding: 0 1.3125rem;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        right: 0;
    }
    &__item-amount {
        justify-content: flex-end;
        font-size: 1rem;
    }
    &__arrow-img {
        width: 1rem;
        height: auto;
        transform: rotate(0);
        transition: transform 0.25s ease;
        &--hidden {
            pointer-events: none;
            visibility: hidden;
        }
    }
    &__cloud-img {
        padding: 0 0.6875rem;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        left: 0;
        .icon {
            height: 2.3125rem;
            width: 2.3125rem;
        }
        .spinner {
            height: 1.5rem;
            width: 1.5rem;
            margin: 0.40625rem;
            position: relative;
        }
    }
    &__recipient {
        line-height: normal;
        word-break: break-word;
    }
    &__file-name {
        font-size: 0.75rem;
        line-height: normal;
        word-break: break-word;
    }
    &__status-text {
        font-size: 0.75rem;
        font-weight: 300;
        font-style: italic;
        line-height: 1;
        color: white;
        margin-bottom: 0.625rem;
        width: 100%;
    }
    &__percentage {
        float: right;
    }
    .button {
        margin-bottom: 0;
        & + .button {
            margin-left: 0.5rem;
        }
    }
    .button--small {
        min-width: 7.5rem;
        max-width: 100%;
        float: right;
    }
    .title--h3 {
        margin-bottom: 0.5rem;
    }
    .data-list {
        &--small {
            .data-list__item {
                flex-grow: initial;
                padding: 0;
            }
        }
    }
    .invoice-image {
        margin-bottom: 0;
    }
    .input__required-information {
        color: lighten($lipstick, 12%);
        font-weight: bold;
    }
    .attachment-list {
        margin-bottom: 1rem;
        padding: 0;
        list-style: none;
    }

    .attachment-button {
        margin-left: 1rem;
        background-color: inherit;
        border: none;
    }
    .attachment-icon {
        margin-left: 1rem;
        display: inline;
    }
    .attachment-button svg {
        width: 16px;
    }
    .attachment-header {
        font-weight: bold;
    }
}

@media all and (max-width: 1400px) {
    .files {
        &__panel {
            min-width: 8rem;
        }
    }
}

@media all and (max-width: 768px) {
    .files {
        margin: 0 -1rem 3rem;
        &__information {
            padding: 0;
        }
        &__item-content {
            padding: 0.625rem 1.125rem;
        }
        &__item-name {
            flex-basis: 100%;
            min-width: initial;
        }
        &__item-row {
            padding: 1.25rem 2.3125rem 1.25rem 2.9375rem;
        }
        &__cloud-img {
            .icon {
                width: 1.5625rem;
                height: 1.5625rem;
            }
            .spinner {
                height: 1rem;
                width: 1rem;
                margin: 0.28125rem;
                position: relative;
            }
        }
        &__panel {
            flex-basis: 100%;
            margin-bottom: 1rem;
            min-width: initial;
            &--divider {
                height: 2px;
                width: 314px;
                border: solid 1px $slate;
                margin-left: auto;
                margin-right: auto;
            }
            &--divider-horizontal {
                height: 2px;
                width: 280px;
                border: solid 1px $slate;
                margin-left: auto;
                margin-right: auto;
                margin-top: -2.6rem;
            }
            &--reverse {
                margin-right: 1rem;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        &__item {
            &--active {
                .files__content-wrapper {
                    flex-direction: column;
                }
            }
        }
    }
}
