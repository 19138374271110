.user-handler {
    display: flex;
    width: 100%;
    margin-bottom: 2rem;
    &__panel {
        justify-content: center;
        display: flex;
        flex-direction: column;
        &--button {
            justify-content: flex-end;
        }
        &:nth-child(2) {
            flex-grow: 2;
        }
        .button {
            margin: auto 0;
            white-space: nowrap;
        }
    }
    .icon--corner-icon {
        margin-right: 1rem;
        width: 30px;
        height: 30px;
        fill: #179e5a;
    }

    .title,
    .caption {
        margin: 0;
        color: white;
    }
    &--small {
        .title,
        .caption {
            font-size: 12px;
        }
    }
}

@media all and (min-width: 767px) {
    .user-handler {
        &--small {
            .title,
            .caption {
                font-size: 1rem;
            }
        }
    }
}

@media all and (max-width: 768px) {
    .user-handler {
        flex-wrap: wrap;
        &__panel {
            &--button {
                margin-top: 1rem;
                width: 100%;
            }
        }
    }
}
