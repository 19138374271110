$offset: 187;
$duration: 1.4s;

.spinner {
    animation: rotator $duration linear infinite;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
}

@keyframes rotator {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(270deg);
    }
}

.path {
    stroke-dasharray: $offset;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: dash $duration ease-in-out infinite, colors ($duration * 4) ease-in-out infinite;
}

@keyframes colors {
    // 0% { stroke: $blue; }
    // 25% { stroke: $lipstick; }
    // 50% { stroke: $saffron }
    // 75% { stroke: $meadow }
    // 100% { stroke: $blue; }
    0% {
        stroke: lighten($spruce, 20%);
    }
    16.6% {
        stroke: $blue;
    }
    33.2% {
        stroke: $tealish;
    }
    49.8% {
        stroke: $meadow;
    }
    66.4% {
        stroke: $saffron;
    }
    83% {
        stroke: $lipstick;
    }
    100% {
        stroke: lighten($spruce, 20%);
    }
}

@keyframes dash {
    0% {
        stroke-dashoffset: $offset;
    }
    50% {
        stroke-dashoffset: $offset/4;
        transform: rotate(135deg);
    }
    100% {
        stroke-dashoffset: $offset;
        transform: rotate(450deg);
    }
}
