.receipt {
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.3);
    background-image: linear-gradient(to bottom, $charcoal-grey, #2a323f);
    animation: slide-up 0.5s ease;
    position: sticky;
    &__wrapper {
        padding: 40px 56px;
        max-width: 1200px;
        margin: auto;
    }
    &:before {
        content: '';
        background-image: url('/img/jagged-corner.png');
        background-repeat: repeat-x;
        height: 1rem;
        display: block;
        position: relative;
        top: -1rem;
    }
    &__row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.5rem;
        align-items: center;
        &--summary {
            margin-top: 2rem;
            .receipt__value {
                font-size: 18px;
                font-weight: bold;
            }
        }
    }
    &__key {
        font-size: 14px;
        font-weight: normal;
    }
    &__value {
        font-size: 16px;
        font-weight: 300;
        color: white;
        &--large {
            font-weight: 400;
            font-size: 18px;
        }
    }
    .data-list,
    .button {
        margin-bottom: 0;
    }
    &--small {
        margin-bottom: 3rem;
        background-image: linear-gradient(to top, $charcoal-grey, lighten($charcoal-grey, 2.5%));
        animation: slide-down 0.5s ease forwards;
        opacity: 0;
        box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.5);
        .receipt {
            &__wrapper {
                padding: 8px 26px;
                position: relative;
                height: 100%;
                // This modifier should only work if the receipt is small intentionally
                &--spacer-top {
                    padding-top: 30px;
                    padding-bottom: 20px;
                }
            }
        }
        &:before {
            content: none;
        }
        &:after {
            content: '';
            transform: scaleY(-1);
            background-image: url('/img/jagged-corner.png');
            background-repeat: repeat-x;
            height: 1rem;
            display: block;
            position: absolute;
            bottom: -0.9375rem;
            width: 100%;
        }
    }
    .table__row {
        animation: appear 0.25s ease;
    }
}

@media all and (max-width: 768px) {
    .receipt {
        &__wrapper {
            padding: 25px 20px 15px;
        }
        &__row {
            flex-wrap: wrap;
        }
    }
}
