.confirm-action {
    animation: slide-up-hack 0.25s ease;
    background: $slate;
    bottom: auto;
    box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.4);
    left: -6px;
    max-width: calc(100vw - 58px);
    padding: 16px;
    position: absolute;
    top: 0;
    transform: translateY(-100%);
    width: max-content;
    z-index: 3;
    &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 10px 0 10px;
        border-color: $slate transparent transparent transparent;
        bottom: -10px;
        right: 0;
        left: 0;
        margin: auto;
    }
    .close {
        position: absolute;
        top: 16px;
        right: 16px;
        padding: 2px;
        &__icon {
            width: 16px;
            height: 16px;
        }
    }
    .paragraph {
        margin-right: 32px;
    }
    .button--chip {
        margin-bottom: 0;
    }
    &--right {
        left: auto;
        right: -6px;
        text-align: left;
    }
    &--lesstop {
        top: -10px;
    }
}
