.cookie-consent {
    position: fixed;
    bottom: 0;
    z-index: 99999;
    background: $charcoal-grey;
    width: 100%;
    padding: 1.875rem 2.5rem;
    box-shadow: 0 0 1.875rem 0 rgba(0, 0, 0, 0.4);
    &__content {
        display: flex;
        justify-content: space-between;
        max-width: 1080px;
        margin: 0 auto;
    }
    &__information {
        max-width: 67.5rem;
    }
    &__buttons {
        padding-left: 1.25rem;
        display: flex;
        align-items: flex-end;
    }
    &__flex {
        display: flex;
        justify-content: space-around;
    }
    .title,
    .paragraph,
    .link {
        color: white;
    }
    .paragraph:last-child {
        margin-bottom: 0;
    }
    .close {
        position: absolute;
    }
}

@media all and (max-width: 1079px) {
    .cookie-consent {
        &__content {
            display: block;
        }
        &__buttons {
            padding-left: 0;
        }
        .paragraph {
            margin-bottom: 1.375rem;
        }
    }
}

@media all and (max-width: 768px) {
    .cookie-consent {
        &__content {
            display: block;
        }
        &__buttons {
            padding-left: 0.4px;
        }
        .paragraph {
            margin-bottom: 1.375rem;
            max-height: 120px;
            overflow: auto;
        }
        .button {
            font-size: 0.8rem;
            padding: 0.55rem 1.1rem;
        }
    }
}
