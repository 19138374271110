.content {
    flex-grow: 1;
    position: relative;
    height: 100%;
    &__body {
        position: relative;
        z-index: 1;
        height: 100%;
        display: flex;
    }
    &__menu {
        flex-basis: 330px;
        flex-shrink: 1;
        flex-grow: 1;
        box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.4);
        background-image: radial-gradient(circle at 0 0, $dark-clay, $ebony);
        position: relative;
        z-index: 1;
        opacity: 1;
        transition: flex-basis 0.25s ease, opacity 0.125s 0.25s ease;
        &--sticky {
            position: sticky;
            top: 0;
            height: 100vh;
        }
        &--filter,
        &--transfer-cost {
            animation: appear 0.5s ease forwards;
            opacity: 0;
            flex-basis: 360px;
            flex-grow: 0;
            flex-shrink: 0;
            overflow: auto;
            padding: 40px 20px 30px;
        }
        &--inactive {
            flex-basis: 0;
            opacity: 0;
            transition: flex-basis 0.25s 0.125s ease, opacity 0.125s ease;
        }
    }
    &__flyer {
        padding: 32px 10px 0;
        flex-basis: 450px;
        flex-shrink: 1;
        flex-grow: 0;
        box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.5);
        background-color: $dark-clay;
        margin: 26px 0;
        animation: slide-right 0.25s ease forwards;
        opacity: 0;

        position: sticky;
        top: 26px;
        height: calc(100vh - 52px);
        overflow: auto;
    }
    &__main {
        flex-grow: 2;
        flex-shrink: 2;
        flex-basis: 1080px;
        box-shadow: 1px 0 15px 0 rgba(0, 0, 0, 0.3);
        // background-image: radial-gradient(circle at 0 0, $dark-clay, $dark);
        background-image: radial-gradient(circle at 0 0, $dark-clay, $dark 72%);
        overflow: auto;
        &--stuffing {
            padding: 44px 55px 33px;
        }
        &--404 {
            padding: 44px 55px 33px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            position: relative;
            .title {
                margin-bottom: 8px;
            }
            .title,
            .caption {
                z-index: 1;
                position: relative;
                text-align: center;
                opacity: 0;
                animation: slide-up 0.5s 0.75s ease forwards;
                font-style: normal;
            }
            .title--huge {
                margin: initial;
                display: inline-block;
                color: $charcoal-grey;
                z-index: 0;
                text-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
                opacity: 0;
                animation: slide-up 0.75s ease forwards;
            }
            .title__huge {
                font-size: 10em;
            }
            .button--function {
                opacity: 0;
                animation: slide-up 0.5s 1.25s ease forwards;
            }
        }
        &--flex {
            display: flex;
            overflow: visible;
            .content__wrapper {
                flex-grow: 2;
                flex-shrink: 2;
                flex-basis: 1080px;
                min-height: 100%;
            }
        }
    }
    &__wrapper {
        max-width: 1200px;
        margin: auto;
        &--stuffing {
            padding: 44px 55px 33px;
        }
        &--center {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        &--reversed {
            display: flex;
            flex-direction: row-reverse;
        }
        &--loading {
            position: relative;
            height: 100%;
            overflow: hidden !important;
        }
    }
    &__section {
        display: flex;
        flex-wrap: wrap;
        margin-right: -0.75rem;
        margin-left: -0.75rem;
    }
    &__section-item {
        flex-basis: 375px;
        flex-grow: 1;
        margin: 0.75rem;
    }
    &__modal {
        background-image: radial-gradient(circle at 0 0, $dark-clay, $dark);
        height: 90vh;
        max-width: 960px;
        width: 100%;
        overflow: auto;
        padding: 55px 66px 33px;
        display: block;
        position: relative;
        margin: auto;
    }
}

@media all and (max-width: 1080px) {
    .content {
        &__menu {
            // display: none;
            &--sticky {
                position: static;
                height: auto;
            }
            &--filter {
                padding: 0;
            }
            &--transfer-cost {
                min-height: 100%;
            }
            .h2 {
                padding-top: 20px;
            }
        }
        &__main {
            min-height: calc(100% - 40px);
            &--flex {
                display: block;
                height: auto;
            }
        }
        &__body {
            display: block;
        }
        &__flyer {
            margin: 0 0 40px;
            position: static;
            height: auto;
        }
        &__modal {
            padding: 44px;
        }
    }
}

@media all and (max-width: 768px) {
    .content {
        &__wrapper {
            padding: 1.875rem 1.875rem 5.625rem;
        }
        &__main {
            &--stuffing {
                padding: 30px 20px 30px;
                .content__wrapper {
                    padding: 0;
                }
            }
            &--404 {
                .title__huge {
                    font-size: 2.5rem;
                }
                .title--h1 {
                    font-size: 1.15rem;
                }
            }
        }
        &__section {
            display: block;
            margin-right: initial;
            margin-left: initial;
        }
        &__section-item {
            margin: initial;
        }
        &__modal {
            padding: 22px;
        }
    }
}
