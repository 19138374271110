.history {
    &,
    .content__main,
    .content__wrapper {
        overflow: visible;
    }
    .content__wrapper--stuffing {
        opacity: 0;
        animation: appear 0.5s 0.25s ease forwards;
    }
    .content__menu {
        flex-grow: 0;
    }
    &__greeting {
        padding: 45px 34px 22px;
    }
    &__search {
        animation: slide-up 0.5s 0.125s ease forwards;
        opacity: 0;
        transform-origin: top;
        position: sticky;
        top: 0;
        z-index: 1;
        padding: 22px;
        margin: 0 0 2rem;
        background: radial-gradient(circle at 0 0, lighten($charcoal-grey, 2%), $charcoal-grey);
        box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.3);
        max-width: 100vw;
        display: flex;
        justify-content: space-between;
        margin-bottom: 2rem;
        .input {
            flex-grow: 1;
            margin-right: 22px;
        }
        .input,
        .button {
            margin-bottom: 0;
        }
    }
    .scrollable {
        opacity: 0;
        animation: appear-up 0.25s ease forwards;
    }
    &__filter-toggler {
        display: none;
    }
}

@media all and (max-width: 1080px) {
    .history {
        &__search {
            flex-wrap: wrap;
            top: 70px;
            .input {
                margin-bottom: 0;
            }
            .button,
            .input__field {
                font-size: 14px;
                padding: 10px 12px;
            }
        }
        &__filter-toggler {
            display: flex;
            z-index: 4;
            color: $textColor;
            font-weight: bold;
            width: 100%;
            text-align: center;
            background: $slate;
            appearance: none;
            border: none;
            align-items: center;
            justify-content: center;
            .icon {
                width: 20px;
                transition: transform 0.25s ease;
                margin-left: 8px;
            }
            &--active {
                .icon {
                    transform: rotate(-180deg);
                }
            }
        }
        &__filter {
            display: none;
            // transform: scaleY(0);
            // animation: expand .25s cubic-bezier(0.04, 1.04, 0.38, 1.01) forwards;
            // animation-delay: .25;
            // transform-origin: top;
            overflow: hidden;
            &--active {
                display: block;
            }
        }
    }
}
