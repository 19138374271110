// Message specific colors
$message: #353f4f;
$messageUser: #216a88;
$messageIconBg: #1e2631;

$blue: #3292ba !default;
$saffron: #f5b13e !default;
$tealish: #1db8b1 !default;
$meadow: #179e5a !default;
$lipstick: #ce2066 !default;
$spruce: #0d394b;
$night: #2c3441;
$dark: #202630;
$shadow: #242a32;
$ebony: #252d39;
$charcoal: #2c343e;
$dark-clay: #2b3340;
$charcoal-grey: #333b4a;
$slate: #495569;

$lynch: #667b98;
$gull: #93a6b8;

$textColor: rgba(white, 0.9);
$divider: rgba(white, 0.2);
$textColorDark: rgba(black, 0.78);

$scroll: $slate;
$scrollTrack: $charcoal-grey;
