.summary-box {
    display: flex;
    align-items: center;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.3);
    background: radial-gradient(circle at 0 0, lighten($charcoal-grey, 2%), $charcoal-grey);
    margin-right: auto;
    margin-bottom: 2rem;
    padding: 1.875rem 2.5rem;
    width: 100%;
    flex-wrap: wrap;
    &__content {
        display: flex;
        flex-wrap: wrap;
        flex-basis: 100%;
    }
    &__panel {
        flex-basis: 50%;
        flex-grow: 1;
        min-width: 12.5rem;
        &--full {
            flex-basis: 100%;
        }
        &--align-bottom {
            text-align: right;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: flex-end;
            padding-top: 1rem;
            margin-bottom: 0;
            margin-top: auto;
            .data--sell {
                font-size: 1.625rem;
                margin-bottom: 1rem;
            }
        }
        &--align-right {
            text-align: right;
        }
        &--small {
            position: relative;
            flex-grow: initial;
            flex-basis: initial;
            margin-left: 20px;
        }
    }
    &__save-state {
        animation: slide-up 0.25s ease;
    }
    .button {
        margin-bottom: 0;
    }

    .data-list {
        margin-bottom: 0;
        &--small {
            .data-list__item {
                padding: 0;
                margin-bottom: 1.125rem;
            }
        }
        &--column {
            .data-list__data {
                font-size: 1rem;
            }
        }
    }

    &--alt {
        background: $message;
        padding: 1.25rem;
        box-shadow: 0 0 30px 3px rgba(0, 0, 0, 0.35);
    }
}
@media all and (max-width: 768px) {
    .summary-box {
        padding: 1.5625rem;
        &__panel {
            &--small {
                flex-basis: 100%;
                margin-left: initial;
                .button {
                }
            }
        }
    }
}
