.icon {
    display: inline-block;
    vertical-align: text-bottom;
    line-height: 1;
    &.icon--huge {
        margin: auto auto 4rem;
        display: block;
        width: 150px;
        height: auto;
    }
}

.icon--arrow-left-white {
    width: 16px;
    height: 16px;
}
.icon--arrow-right-white {
    width: 16px;
    height: 16px;
}
.icon--attachment {
    width: 18px;
    height: 19px;
}
.icon--calendar-money {
    width: 40px;
    height: 40px;
}
.icon--check {
    width: 15px;
    height: 15px;
}
.icon--check-small {
    width: 31px;
    height: 31px;
}
.icon--clock {
    width: 26px;
    height: 26px;
}
.icon--clock-active {
    width: 26px;
    height: 26px;
}
.icon--close {
    width: 29px;
    height: 29px;
}
.icon--cloud-base {
    width: 47px;
    height: 47px;
}
.icon--corner-icon {
    width: 45px;
    height: 45px;
}
.icon--credit-lookup {
    width: 24px;
    height: 23px;
}
.icon--cross {
    width: 15px;
    height: 15px;
}
.icon--cross-small {
    width: 31px;
    height: 31px;
}
.icon--dashboard {
    width: 26px;
    height: 26px;
}
.icon--dashboard-active {
    width: 26px;
    height: 26px;
}
.icon--down {
    width: 37px;
    height: 37px;
}
.icon--download {
    width: 19px;
    height: 19px;
}
.icon--edit {
    width: 16px;
    height: 16px;
}
.icon--empty-list {
    width: 296px;
    height: 235px;
}
.icon--external {
    width: 19px;
    height: 19px;
}
.icon--fairy {
    width: 30px;
    height: 33px;
}
.icon--inspect {
    width: 25px;
    height: 26px;
}
.icon--invoice {
    width: 26px;
    height: 26px;
}
.icon--invoice-active {
    width: 26px;
    height: 26px;
}
.icon--invoice-small {
    width: 31px;
    height: 31px;
}
.icon--left {
    width: 37px;
    height: 37px;
}
.icon--lock {
    width: 31px;
    height: 31px;
}
.icon--logout {
    width: 17px;
    height: 15px;
}
.icon--money {
    width: 36px;
    height: 37px;
}
.icon--money-2 {
    width: 31px;
    height: 31px;
}
.icon--pdf {
    width: 29px;
    height: 28px;
}
.icon--recipient {
    width: 26px;
    height: 26px;
}
.icon--recipient-active {
    width: 26px;
    height: 26px;
}
.icon--right {
    width: 37px;
    height: 37px;
}
.icon--search-invoice {
    width: 23px;
    height: 23px;
}
.icon--settings {
    width: 19px;
    height: 19px;
}
.icon--settings-active {
    width: 19px;
    height: 19px;
}
.icon--speech {
    width: 31px;
    height: 31px;
}
.icon--speech-2 {
    width: 31px;
    height: 31px;
}
.icon--speech-2-almost {
    width: 31px;
    height: 31px;
}
.icon--speech-2-error {
    width: 31px;
    height: 31px;
}
.icon--support {
    width: 24px;
    height: 24px;
}
.icon--tingeling-icon {
    width: 45px;
    height: 45px;
}
.icon--trash {
    width: 23px;
    height: 26px;
}
.icon--up {
    width: 37px;
    height: 37px;
}
.icon--upload {
    width: 36px;
    height: 37px;
}
.icon--upload-alt {
    width: 23px;
    height: 23px;
}
.icon--upload-cloud {
    width: 31px;
    height: 31px;
}
.icon--wait {
    width: 34px;
    height: 34px;
}
.icon--wait-status {
    width: 23px;
    height: 23px;
}
.icon--zoom-in {
    width: 25px;
    height: 26px;
}
.icon--zoom-out {
    width: 25px;
    height: 26px;
}
