.dashboard {
    &__upload {
        overflow: hidden;
    }
    &__greeting {
        padding: 45px 34px 22px;
    }
    &__chart {
        background-color: $dark;
        padding: 40px 70px;
    }
    &__table {
        display: flex;
        padding: 40px 70px;
    }
    &__gauge {
        width: 240px;
        margin-right: 22px;
    }
    &__gauge-header {
        font-size: 12px;
        text-align: center;
    }
    &__square {
        padding-top: 10px;
        background-color: $dark;
    }
    .title--h2 {
        margin-bottom: 44px;
    }
    .chart--gauge {
        padding: 0 22px 17px;
    }
    .messages {
        padding: 32px 62px;
    }
}
