.lightbox {
    height: 90vh;
    background-image: radial-gradient(circle at 0 0, $dark-clay, $dark);
    padding: 50px;
    &__img-wrapper {
        overflow: hidden;
        height: 100%;
        cursor: grab;
        text-align: center;
        &:active {
            cursor: grabbing;
        }
    }
    &__img {
        height: 100%;
        object-fit: contain;
        transform-origin: center center;
    }
    &__zoom {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        background-image: linear-gradient(to bottom, $charcoal-grey, $dark-clay);
        box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.4);
        display: inline-block;
        width: 350px;
        max-width: 100%;
        padding: 20px;
    }
    &__zoom-controls {
        display: flex;
        .input-range {
            margin: 0 12px;
        }
    }
    &__zoom-percent {
        font-size: 14px;
        font-weight: 600;
        text-align: center;
    }
    .button--chip {
        position: absolute;
        right: 10px;
        bottom: -10px;
    }
}

@media all and (max-width: 1079px) {
    .lightbox {
        height: 100%;
        overflow: hidden;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        margin: auto;
        padding: 25px;
    }
}
