.data-list {
    margin-bottom: 44px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
    &__item {
        margin-bottom: 18px;
        flex-basis: 49%;
        min-width: 190px;
        flex-grow: 1;
        .icon--speech,
        .icon--speech-almost {
            position: absolute;
            width: 14px;
            height: 14px;
            top: 0;
            left: -19px;
        }
        &--full {
            margin-bottom: 2rem;
            flex-basis: 100%;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .data-list__label {
                flex-basis: 100%;
            }
            .button--chip {
                margin: auto 0;
            }
            .title {
                margin-bottom: 10px;
                margin-top: 16px;
                margin-right: 10px;
                flex-basis: 100%;
            }
            .button--chip {
                margin: auto 0;
            }
            .invoice-specification__back-button {
                margin-bottom: 0;
            }
        }
        &--error {
            flex-basis: 100%;
        }
        &--spacer-top {
            margin-top: 2rem;
        }
        &--align-right {
            text-align: right;
        }
    }
    &__loader {
        position: relative;
        height: 60px;

        margin-bottom: 18px;
        flex-basis: 49%;
        flex-grow: 1;
    }
    &__label {
        position: relative;
        font-size: 13px;
    }
    &__data {
        font-family: 'Poppins', sans-serif;
        font-size: 18px;
        font-weight: 600;
        .caption {
            font-style: normal;
        }
    }
    &__support {
        position: sticky;
        bottom: 0;
        z-index: 2;
        text-align: right;
        margin-bottom: 0;
        padding: 0;
    }
    &__divider {
        border-bottom: 1px solid $divider;
        flex-basis: 100%;
        margin-bottom: 1rem;
    }
    .spinner {
        width: 3rem;
        height: 3rem;
    }
    &--column {
        display: block;
    }
    &--padding {
        .data-list {
            &__item {
                padding: 0 14px;
            }
        }
    }
    &--small {
        .data-list {
            &__item {
                padding: 0 14px;
                margin-bottom: 8px;
            }
            &__label {
                font-size: 12px;
            }
            &__data {
                font-size: 13px;
            }
        }
    }
    &--small-items {
        .data-list {
            &__item {
                flex-basis: 24%;
            }
        }
    }
    &--third-items {
        .data-list {
            &__item {
                flex-basis: 32%;
            }
        }
    }
}
