.finalize-sell {
    &__section {
        margin-bottom: 2rem;
        &:after {
            content: '';
            display: table;
            clear: both;
        }
    }
    &__receipt {
        content: '';
        background-image: url('/img/jagged-corner.png');
        background-repeat: repeat-x;
        height: 1rem;
        display: block;
        position: relative;
    }
    &__receipt-content {
        padding: 2rem;
        background-image: linear-gradient(to top, $charcoal-grey);
        min-height: 15rem;
        .invoice-list {
            &__item {
                &-content {
                    grid-template-columns: repeat(4, 1fr);
                }
            }
        }
        .data-list {
            margin-bottom: 0;
            &__item {
                flex-basis: 33%;
            }
        }
    }
    &__receipt-next {
        padding: 2rem 0;
        .payment-details {
            display: flex;
            flex-grow: 1;
            &__account {
                padding-right: 10%;
            }
        }
    }
    &__support-button.button {
        background: $slate;
        box-shadow: none;
        border: none;
        box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.2);
        padding: 0.5rem 0.75rem;
    }
    &__support-box {
        animation: slide-up 0.5s ease;
    }
}
