.invoice-specification {
    &__content {
        @extend .content__main--stuffing;
        box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.4);
        background-image: radial-gradient(circle at 0 0, $dark-clay, $ebony);
        flex-basis: 690px;
        flex-grow: 1;
        z-index: 1;
    }
    &__info {
        flex-basis: 350px;
        flex-grow: 1;
        padding: 60px 35px;
        background-image: radial-gradient(circle at 0 0, $dark-clay, $dark);
        &::-webkit-scrollbar {
            display: none;
        }
    }
    &__back-button {
        // position: absolute;
        top: 33px;
        left: 33px;
    }
    &__loader {
        position: relative;
        min-height: 150px;
        .spinner {
            width: 40px;
            height: 40px;
        }
    }
    .messages {
        padding: 0;
    }
    .data-list {
        @extend .content__main--stuffing;
        margin: -44px -55px 33px;
        background-image: linear-gradient(to top, $charcoal-grey, $dark-clay);
        &__item:not(.data-list__item--full) {
            animation: fade-in 0.5s 0.125s ease forwards;
            opacity: 0;
        }
    }
}
@media all and (min-width: 1081px) {
    .invoice-specification {
        &__info {
            overflow: auto;
            max-height: 100vh;
            position: sticky;
            top: 0;
            scrollbar-width: none;
        }
        .content__main {
            flex-basis: initial;
            display: flex;
            overflow: visible;
        }
        .invoice-image {
            max-width: 900px;
        }
    }
}
@media all and (max-width: 1080px) {
    .invoice-specification {
        flex-wrap: wrap;
        &__info {
            flex-basis: 100%;
            flex-grow: 1;
            padding: 60px 35px;
        }
        .invoice-image {
            flex-basis: 100%;
            flex-grow: 1;
        }
    }
}

@media all and (max-width: 768px) {
    .invoice-specification {
        &__info {
            padding: 40px 24px;
        }

        .messages {
            padding: 0 12px 0 0;
        }
        .data-list {
            margin: -30px -20px -30px;
        }
    }
}
