@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes appear {
    from {
        opacity: 0;
        transform: translateY(-0.3125rem);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes appear-up {
    from {
        opacity: 0;
        transform: translateY(0.3125rem);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slide-in {
    from {
        opacity: 0;
        transform: translateY(100%);
    }
    25% {
        opacity: 0;
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slide-right {
    from {
        opacity: 0;
        transform: scaleX(0) translatex(-100%);
    }
    25% {
        opacity: 0;
        transform: scaleX(1) translatex(-50%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slide-up {
    from {
        opacity: 0;
        transform: translateY(25%);
    }
    25% {
        opacity: 0;
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slide-down {
    from {
        opacity: 0;
        transform: translateY(-25%);
    }
    25% {
        opacity: 0;
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes expand {
    from {
        transform: scaleY(0);
    }
    to {
        transform: scaleY(1);
    }
}

@keyframes pop {
    from {
        opacity: 0;
        transform: translateY(-0.9375rem) scaleY(0);
    }
    to {
        opacity: 1;
        transform: translateY(0) scaleY(1);
    }
}

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-0.0625rem, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(0.125rem, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-0.25rem, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(0.25rem, 0, 0);
    }
}

@keyframes previousArrow {
    from {
        transform: translateX(0);
    }
    50% {
        transform: translateX(-0.625rem);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes nextArrow {
    from {
        transform: translateX(0);
    }
    50% {
        transform: translateX(0.625rem);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes commitment-enter {
    from {
        transform: translateY(20px) translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateY(0) translateX(100%);
        opacity: 1;
    }
}

@keyframes commitment-leave {
    from {
        transform: translateY(0) translateX(100%);
        opacity: 1;
    }
    to {
        transform: translateY(20px) translateX(100%);
        opacity: 0;
    }
}

@keyframes mobile-commitment-enter {
    from {
        transform: translateY(20px) translateY(-100%);
        opacity: 0;
    }
    to {
        transform: translateY(0) translateY(-100%);
        opacity: 1;
    }
}

@keyframes mobile-commitment-leave {
    from {
        transform: translateY(0) translateY(-100%);
        opacity: 1;
    }
    to {
        transform: translateY(20px) translateY(-100%);
        opacity: 0;
    }
}

@keyframes bank-id-expand {
    from {
        transform: scale(1);
        opacity: 1;
    }
    66% {
        transform: scale(3);
        opacity: 0;
    }
    to {
        transform: scale(3);
        opacity: 0;
    }
}

@keyframes bar-progressing {
    from {
        box-shadow: 0 0 10px 0 rgba($meadow, 0.5);
    }
    50% {
        box-shadow: 0 0 20px 2px rgba($meadow, 0.5);
    }

    to {
        box-shadow: 0 0 10px 0 rgba($meadow, 0.5);
    }
}

@keyframes slide-up-hack {
    from {
        opacity: 0;
        transform: translateY(-75%);
    }
    25% {
        opacity: 0;
    }
    to {
        opacity: 1;
        transform: translateY(-100%);
    }
}
