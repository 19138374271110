body {
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $textColor;
    font-size: 100%;
    line-height: normal;
}

button,
p {
    font-family: 'Open Sans', sans-serif;
}
.title,
h1,
h2,
h3 {
    margin-top: 0;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    font-weight: bold;
    margin-bottom: 1.375rem;
    margin-top: 0;
    color: $textColor;
    font-family: 'Poppins', sans-serif;
    &:after {
        content: '';
        display: table;
        clear: both;
    }
}
h1,
.title--h1 {
    font-size: 1.75rem;
}
h2,
.title--h2 {
    font-size: 1.375rem;
    font-weight: 600;
}

h3,
.title--h3 {
    font-size: 1.25rem;
    font-weight: 600;
}

h4,
.title--h4 {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 8px;
}

.title {
    &--huge {
        margin: auto auto 1.375rem;
        display: block;
        width: 100%;
        text-align: center;
    }
    &__tiny {
        font-size: 2.25rem;
        line-height: 1;
        font-weight: normal;
    }
    &__huge {
        font-size: 6.0625rem;
        display: block;
        line-height: 1;
        font-weight: bold;
    }
    &--center {
        text-align: center;
    }
    &--spacer {
        margin-bottom: 1.25rem;
    }
    img {
        display: inline-block;
        vertical-align: middle;
        margin-right: 1em;
        float: left;
    }
}

.data {
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    &--sell {
        color: $meadow;
    }
    &--wait {
        color: $saffron;
    }
    &--warning {
        color: $lipstick;
    }
}

p,
.paragraph {
    color: $textColor;
    font-size: 1rem;
    line-height: normal;
    margin-bottom: 1.375rem;
    margin-top: 0;

    &--spacer {
        margin-bottom: 2.75rem;
    }
    &--no-spacer {
        margin-bottom: 0;
    }
}

ol {
    list-style-type: lower-roman;
    @extend .paragraph;
}

.caption {
    font-size: 14px;
    font-style: italic;
    line-height: normal;
    color: $textColor;
    font-weight: normal;
    &--spacer {
        margin-bottom: 0.5rem;
    }
    &--spacer-top {
        margin-top: 0.5rem;
    }
}

.link {
    transition: all 0.125s ease;
    cursor: pointer;
    &,
    &:link,
    &:visited,
    &:active {
        color: darken($blue, 20%);
        font-weight: 600;
        text-decoration: none;
    }
    &:hover {
        color: darken($blue, 10%);
    }
    &:active {
        color: $blue;
    }

    &--light {
        &,
        &:link,
        &:visited,
        &:active {
            color: lighten($blue, 20%);
            font-weight: 600;
            text-decoration: none;
        }
        &:hover {
            color: lighten($blue, 10%);
        }
        &:active {
            color: $blue;
        }
    }
}
button.link {
    appearance: none;
    background: transparent;
    padding: none;
    margin: auto;
    display: inline;
    border: none;
}

small,
.small {
    font-size: 0.75rem;
}

mark,
.mark {
    background: darken(desaturate($blue, 30%), 8%);
    color: white;
}
pre {
    background: $slate;
    padding: 0.75rem;
    margin-bottom: 1.25rem;
    font-size: 0.875rem;
}

.sr {
    width: 0px;
    height: 0px;
    overflow: hidden;
    display: block;
    position: absolute;
}

@media all and (max-width: 768px) {
    h1,
    .title {
        font-size: 1.625rem;
    }
    h2,
    .title--h2 {
        font-size: 1.25rem;
    }
    h3,
    .title--h3 {
        font-size: 1.125rem;
    }

    h4,
    .title--h4 {
        font-size: 1rem;
    }
}

@media all and (max-width: 1080px) {
    h2,
    .title--h2 {
        font-size: 1.175rem;
    }
}
