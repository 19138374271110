.push-notification {
    @extend .information-modal;
    overflow: auto;
    max-width: 800px;
    &__content {
        animation: appear 0.75s 0.125s ease forwards;
        opacity: 0;
    }
    &__actions {
        display: flex;
        .paragraph {
            margin-bottom: 0.5rem;
        }
        flex-direction: row-reverse;
    }
    &__screenshots {
        margin-bottom: 10px;
    }
    .caption {
        display: block;
    }
}
