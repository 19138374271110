.recipient {
    .content__main,
    .content__wrapper {
        overflow: visible;
    }
    .content__wrapper--stuffing {
        opacity: 0;
        animation: appear 0.5s 0.25s ease forwards;
    }
    &__search {
        animation: slide-up 0.5s 0.125s ease forwards;
        opacity: 0;
        transform-origin: top;
        position: sticky;
        top: 0;
        z-index: 1;
        padding: 22px;
        margin: 0 0 2rem;
        background: radial-gradient(circle at 0 0, lighten($charcoal-grey, 2%), $charcoal-grey);
        box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.3);
        max-width: 100vw;
        display: flex;
        justify-content: space-between;
        margin-bottom: 2rem;
        .input {
            flex-grow: 1;
            margin-right: 22px;
        }
        .input,
        .button {
            margin-bottom: 0;
        }
    }
    .scrollable {
        opacity: 0;
        animation: appear-up 0.25s ease forwards;
    }
    &__list {
        animation: appear 0.5s 0.125s ease forwards;
        opacity: 0;
        list-style-type: none;
        padding: 0;
        margin: 0;
        margin-bottom: 3rem;
    }
    &__item {
        &--loader {
            position: relative;
            height: 80px;
            .spinner {
                width: 40px;
                height: 40px;
            }
        }
    }
    &__link {
        display: flex;
        position: relative;
        justify-content: space-between;
        padding: 1rem;
        //cursor: pointer;
        transition: background 0.25s ease;
        border-bottom: 1px solid $divider;
        &:hover,
        &:focus {
            background: rgba(black, 0.2);
        }
        &:link {
            text-decoration: none;
        }
        &--non-clickable {
            &:hover,
            &:focus {
                background: transparent;
                cursor: default;
            }
        }
    }
    &__panel {
        display: flex;
        flex-direction: column;
        padding: 0 4px;
        justify-content: center;
        &--icon {
            flex-grow: 0;
            &:first-child {
                padding-left: 0;
                margin-right: 8px;
            }
            &:last-child {
                padding-right: 0;
                text-align: right;
                align-items: flex-end;
                margin-left: 8px;
            }
        }
        &--state {
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
        }
        .button--chip {
            margin: auto 0;
        }
    }
    &__item-content {
        grid-template-columns: 2fr repeat(4, 1fr);
        display: grid;
        width: 100%;
    }
    &__total-count {
        position: fixed;
        bottom: 0;
        right: 20px;
        background: radial-gradient(circle at 0 0, lighten($charcoal-grey, 2%), $charcoal-grey);
        box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.3);
        padding: 15px 20px;
        font-size: 14px;
        font-weight: bold;
    }
    .paragraph,
    .caption {
        margin-bottom: 0;
    }
    .icon--right {
        width: 16px;
    }
}

@media all and (min-width: 1080px) and (max-width: 1279px) {
    .recipient {
        &__search {
            flex-wrap: wrap;
            top: 70px;
            .input {
                margin-bottom: 0;
            }
            .button,
            .input__field {
                font-size: 14px;
                padding: 10px 12px;
            }
        }
        &__item-content {
            grid-template-columns: repeat(3, 1fr);
            .recipient__panel {
                &:first-child {
                    grid-column-end: span 2;
                }
            }
        }
        &__panel {
            &--icon:first-child {
                display: none;
            }
            &--state {
                position: absolute;
                top: 20px;
                right: 12px;
                margin-right: 0;
            }
        }
        .button--chip {
            font-size: 0;
            width: 12px;
            height: 12px;
            overflow: hidden;
            padding: 0;
            border-radius: 50%;
            border: none;
            &.button {
                &--approve {
                    background: $meadow;
                }
                &--wait {
                    background: $saffron;
                }
                &--warning {
                    background: $lipstick;
                }
                &--function {
                    background: $blue;
                }
            }
        }
    }
}

@media all and (max-width: 768px) {
    .recipient {
        &__item-content {
            display: flex;
            flex-wrap: wrap;
            .recipient__panel {
                flex-basis: 130px;
                flex-grow: 1;
                margin-bottom: 8px;
                &:first-child {
                    flex-basis: 100%;
                }
            }
        }
        .button--chip {
            font-size: 0;
            width: 12px;
            height: 12px;
            overflow: hidden;
            padding: 0;
            border-radius: 50%;
            border: none;
            &.button {
                &--approve {
                    background: $meadow;
                }
                &--wait {
                    background: $saffron;
                }
                &--warning {
                    background: $lipstick;
                }
                &--function {
                    background: $blue;
                }
            }
        }
        &__panel {
            &--icon:first-child {
                display: none;
            }
            &--state {
                position: absolute;
                top: 20px;
                right: 12px;
                margin-right: 0;
            }
        }
    }
}
