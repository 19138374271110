.imports {
    padding: 0;
    margin: 0 0 3rem;
    list-style: none;
    &__async-status {
        background: radial-gradient(circle at 0 0, lighten($charcoal-grey, 2%), $charcoal-grey);
        box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.3);
        width: 100%;
        padding: 1.375rem;
        margin-top: 1rem;
        margin-bottom: 2.0625rem;
        position: relative;

        &--error {
            background: lighten($saffron, 0%);
            color: $textColorDark;
        }
    }
    &__empty {
        margin: auto;
        text-align: center;
        position: relative;
        .icon {
            height: auto;
            width: auto;
            max-width: 100%;
        }
        .button--function {
            display: none;
        }
    }
    &__item {
        border-bottom: 1px solid $divider;
        transition: 0.125s background ease;
        &--active {
            box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.3);
            .imports__item-row {
                background: $charcoal-grey;
            }
            .imports__content-wrapper {
                background-image: linear-gradient(to bottom, $charcoal-grey, $dark-clay);
                display: flex;
                animation-delay: 0;
            }
            .imports__content {
                animation-delay: 0.125s;
            }
            .imports__arrow-img {
                transform: rotate(180deg);
            }
            border-color: transparent;
        }
    }
    &__content-wrapper {
        display: none;
        transform: scaleY(0);
        animation: expand 0.25s ease forwards;
        animation-delay: 0;
        transform-origin: top;
    }
    &__item-content {
        width: 100%;
        padding: 1rem 2.0625rem;
        opacity: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        animation: appear 0.25s ease forwards;
        animation-delay: 0.25s;
    }
    &__panel {
        flex-basis: 50%;
        min-width: 11rem;
        max-width: 100%;
        flex-grow: 1;
        position: relative;
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;
        &-loading {
            margin-bottom: 2rem;
            .caption {
                line-height: 1;
                margin-bottom: 0;
            }
            .spinner {
                width: 1.1875rem;
                height: 1.1875rem;
                position: relative;
                display: inline-block;
                vertical-align: middle;
                margin-right: 1rem;
            }
        }
        .confirm-action {
            top: -20px;
            &:after {
                right: auto;
                left: 40px;
            }
        }
        .input {
            flex-grow: 0;
            margin-bottom: 0;
            opacity: 0;
            animation: slide-up 0.5s ease forwards;
        }
        &--divider {
            width: 2px;
            height: 190px;
            margin: 13px 16px 25px;
            border: solid 1px $slate;
        }
    }
    &__item-row {
        padding: 0.5rem 3.625rem 0.5rem 4.5rem;
        transition: background 0.25s ease, padding 0.125s ease;
        width: 100%;
        position: relative;
        display: flex;
        min-height: 3.75rem;
        cursor: pointer;
        user-select: none;
        &--button {
            padding-right: 1.3125rem;
            cursor: default;
        }
        &--switch {
            cursor: default;
        }
    }
    &__item-amount,
    &__item-arrow,
    &__arrow-img,
    &__item-button {
        display: inline-flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 0.25rem 0;
        .caption {
            margin-bottom: 0;
        }
    }
    &__arrow-img {
        margin-bottom: 0;
    }
    &__item-amount {
        justify-content: flex-end;
        font-size: 1rem;
    }
    &__item-arrow {
        padding: 0 1.3125rem;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        right: 0;
    }
    &__arrow-img {
        width: 1rem;
        height: auto;
        transform: rotate(0);
        transition: transform 0.25s ease;
        &--hidden {
            pointer-events: none;
            visibility: hidden;
        }
    }
    .button {
        margin-bottom: 0;
        & + .button {
            margin-left: 0.5rem;
        }
    }
    .button--small {
        min-width: 7.5rem;
        max-width: 100%;
        float: right;
    }
    .title--h3 {
        margin-bottom: 0.5rem;
    }
    .data-list {
        &--small {
            .data-list__item {
                flex-grow: initial;
                padding: 0;
            }
        }
    }
    .invoice-image {
        margin-bottom: 0;
    }
    .files {
        animation: appear 0.25s 0.5s ease forwards;
        opacity: 0;
    }
    .submit-imports {
        animation: appear 0.25s 0.5s ease forwards;
        opacity: 0;
    }

    .input__required-information {
        color: lighten($lipstick, 12%);
        font-weight: bold;
    }
}

@media all and (max-width: 1400px) {
    .imports {
        &__panel {
            min-width: 8rem;
        }
    }
}

@media all and (max-width: 1230px) {
    .imports {
        &__panel {
            min-width: 4rem;
        }
    }
}

@media all and (max-width: 880px) {
    .imports {
        margin: 0 -1rem 3rem;
        &__item-content {
            grid-template-columns: repeat(3, 1fr);
            .invoice-list__panel {
                &:first-child {
                    grid-column-end: span 2;
                }
            }
        }
        &__panel {
            &--icon:first-child {
                display: none;
            }
            &--state {
                position: absolute;
                top: 20px;
                right: 12px;
                margin-right: 0;
            }
            &--divider {
                height: 2px;
                width: 314px;
                border: solid 1px $slate;
                margin-left: auto;
                margin-right: auto;
                margin-top: -1.6rem;
            }
        }
        &__information {
            padding: 0;
        }
        &__item-name {
            flex-basis: 100%;
            min-width: initial;
        }
        &__item-row {
            padding: 1.25rem 2.3125rem 1.25rem 2.9375rem;
            flex-wrap: wrap;
        }
        &__empty {
            .button--function {
                display: block;
                margin: auto;
            }
        }
        &__item {
            &--active {
                .imports__content-wrapper {
                    flex-direction: column;
                }
            }
        }
    }
}
