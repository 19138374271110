.messages {
    padding: 22px;
    font-size: 0.875rem;
    position: relative;
    &__close {
        position: absolute;
        right: 33px;
        top: 33px;
        background: none;
        appearance: none;
        border: none;
        padding: 0;
        line-height: 1;
    }
    &__container {
        margin-bottom: 32px;
        opacity: 0;
        animation: slide-up 0.4s cubic-bezier(0.44, -0.04, 0.39, 1.32) forwards;
        &--user {
            .messages__author {
                text-align: right;
            }
            .messages__item {
                float: right;
                box-shadow: 0 0 30px 3px rgba(0, 0, 0, 0.35);
                background-image: radial-gradient(circle at 100% 0, $blue, #3265ba);
            }
        }
        &--loader {
            position: relative;
            height: 140px;
        }
        &:after {
            content: '';
            display: table;
            clear: both;
        }
    }
    &__author {
        margin-bottom: 11px;
        font-size: 14px;
        font-weight: 600;
        font-family: 'Poppins', sans-serif;
    }
    &__timestamp {
        margin-left: 0.6875rem;
        font-size: 0.75rem;
        font-weight: normal;
        font-family: 'Open Sans', sans-serif;
    }
    &__item {
        max-width: 350px;
        width: 100%;
        background-image: radial-gradient(circle at 0 0, lighten($charcoal-grey, 2%), $charcoal-grey);
        padding: 1.125rem 2.4375rem 1.125rem 1.125rem;
        position: relative;
        box-shadow: 0 0 30px 3px rgba(0, 0, 0, 0.35);
        fill: white;
        &--wait {
            .messages__icon {
                padding: 0;
                .icon {
                    width: 32px;
                    height: 32px;
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
        &--warning {
            .messages__icon {
                background: $lipstick;
            }
        }
        &--function {
            .messages__icon {
                background: $blue;
            }
        }
    }
    &__icon {
        position: absolute;
        bottom: -16px;
        right: -16px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: $messageIconBg;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 7px;
        background-color: $meadow;
        .icon {
            max-width: 100%;
            max-height: 100%;
        }
    }
    &__system-info {
        margin-top: 0.375rem;
        font-size: 0.75rem;
    }
    .paragraph {
        font-size: inherit;
        margin-bottom: 0;
        + .paragraph {
            margin-top: 12px;
        }
    }
    .button--chip {
        margin-bottom: 0;
    }
    .spinner {
        width: 3rem;
        height: 3rem;
    }
    .title--h1 {
        margin-bottom: 0;
    }
    .title--h2 {
        margin-bottom: 1rem;
    }
    .button--secondary {
        margin-bottom: 33px;
    }
}
