.recipient-picker {
    max-width: 500px;
    width: 100%;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.4);
    background: darken($slate, 7.5%);
    user-select: none;
    margin-bottom: 24px;
    &__autocomplete {
        z-index: 1;
        color: white;
        width: 100%;
        box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.4);
    }
    &__search {
        animation: slide-up 0.5s ease forwards;
        opacity: 0;
        padding: 16px;
        flex-grow: 1;
        background: darken($slate, 7.5%);
    }
    &__click-handler {
        flex-grow: 1;
        flex-basis: 100%;
        padding: 16px;
        order: 1;
        position: relative;
        background: $slate;
        min-height: 62px;
        justify-content: center;
        display: flex;
        flex-direction: column;
        > .caption,
        > .data,
        > .caption {
            animation: slide-up 0.5s ease forwards;
            pointer-events: none;
            line-height: 1;
            margin: 0;
        }
        > .data {
            line-height: 1.2;
            padding-right: 1.375rem;
            margin-bottom: 0.25rem;
        }
        .caption {
            font-style: normal;
        }
    }
    &__suggestion-item {
        transition: background 0.25s ease;
        background: darken($slate, 7.5%);
        animation: slide-down 0.5s ease forwards;
        opacity: 0;
        &:hover {
            background: darken($slate, 12.5%);
        }
        &--active {
            background: darken($slate, 0%);
            &:hover {
                background: lighten($slate, 15%);
            }
            .recipient-picker__suggestion-text--button {
                &:focus {
                    background: darken($slate, 15%);
                }
            }
        }
    }
    &__suggestion-text {
        display: inline-block;
        user-select: none;
        padding: 12px 20px;
        &--button {
            cursor: pointer;
            appearance: none;
            background: transparent;
            display: block;
            color: inherit;
            border: none;
            width: 100%;
            text-align: left;
            &:focus {
                outline: none;
                background: darken($slate, 12.5%);
            }
        }
        &--spinner {
            width: 100%;
        }
    }
    &__chosen-item {
        position: relative;
        user-select: none;
        z-index: 2;
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        min-height: 33px;
        flex-wrap: wrap;
    }
    &__list {
        padding: 0;
        margin: 0;
        list-style: none;
        max-height: 207px;
        overflow: auto;
        width: 100%;
    }
    .icon--down {
        width: 16px;
        height: 16px;
        transition: transform 0.25s ease;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 20px;
        display: block;
        margin: auto;
    }
    .input--small {
        display: inline-block;
        vertical-align: middle;
        margin-bottom: -6px;
        margin-top: -6px;
        width: calc(100% - 80px);
        .input__field {
            padding: 8px 12px;
        }
    }
    .button--small {
        display: inline-block;
        vertical-align: middle;
        margin-left: 8px;
        margin-bottom: -3px;
        margin-top: -3px;
        font-size: 12px;
        padding: 7px;
        min-width: initial;
    }
    .spinner {
        position: relative;
        display: block;
        margin: auto;
        height: 25px;
        width: 25px;
    }
    &--active {
        z-index: 3;
        position: relative;
        .icon--down {
            transform: rotate(180deg);
        }
    }
}
