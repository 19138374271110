.commitment-picker {
    max-width: 800px;
    width: 100%;
    margin: auto;
    background: $dark;
    color: white;
    padding: 33px;
    &__list {
        margin: 0;
        padding: 0;
        list-style: none;
        width: 100%;
        max-height: 550px;
        overflow-y: auto;
    }
    &__item {
        appearance: none;
        background: none;
        border: none;
        display: flex;
        width: 100%;
        justify-content: space-between;
        border-bottom: 1px solid $divider;
        align-items: center;
        transition: background 0.5s ease;
        cursor: pointer;
        &:last-child {
            border-bottom: none;
        }
        &:hover {
            background: rgba(0, 0, 0, 0.2);
        }
        &--disabled {
            pointer-events: none;
        }
    }
    &__inline-spinner {
        position: absolute;
        right: 0;
        top: 0;
        width: 40px;
        height: 40px;
        .spinner {
            width: 40px;
            height: 40px;
        }
    }
    &__panel {
        flex-basis: 31%;
        padding: 1rem 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: initial;
        &:first-child {
            flex-grow: 2;
            padding-left: 1rem;
        }
        &:last-child {
            display: block;
            text-align: right;
            padding-right: 1rem;
        }
        .paragraph,
        .caption {
            margin-bottom: 0;
            font-style: normal;
        }
    }
    .close {
        position: absolute;
        top: 22px;
        right: 16px;
        width: 28px;
        height: 28px;
        padding: 3px 6px;
        .icon {
            width: 100%;
            height: 100%;
        }
    }
    .icon--right,
    .icon--external {
        display: inline-block;
        vertical-align: middle;
        height: 16px;
        width: 37px;
    }
    .paragraph,
    .caption,
    .title,
    .link {
        color: white;
    }
    > .spinner {
        position: static;
        margin: 0 auto;
        display: block;
    }
    .title--h2 {
        position: relative;
        padding-right: 45px;
    }
    .title--h4 {
        margin-top: 1rem;
    }
    &--small {
        .commitment-picker {
            &__panel {
                &:first-child {
                    padding-left: 0;
                }
                &:last-child {
                    padding-right: 0;
                }
            }
        }

        .spinner {
            position: relative;
            height: 20px;
            width: 20px;
            vertical-align: middle;
            margin-left: 1rem;
        }
        .icon--right,
        .icon--external {
            display: inline-block;
            vertical-align: middle;
            height: 14px;
            width: 30px;
        }
    }
}

@media all and (max-width: 768px) {
    .commitment-picker {
        &__item {
            flex-direction: column;
            padding: 0.5rem 0;
            align-items: flex-start;
            position: relative;
            padding-right: 43px;
        }
        &__panel {
            padding: 0 1rem;
            &:last-child {
                display: flex;
                justify-content: center;
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
            }
        }
    }
}
