.page {
    display: flex;
    width: 100%;
    height: 100%;
}

@media all and (max-width: 1080px) {
    .page {
        display: block;
        overflow: visible;
    }
}
