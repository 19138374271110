.invoice-image {
    margin-bottom: 2rem;
    &__invoice {
        display: flex;
        flex-grow: 2;
        position: relative;
        overflow: hidden;
        align-items: center;
        flex-wrap: wrap;
        justify-content: unset;
        margin: -8px;
    }
    &__item {
        flex-basis: 170px;
        min-width: 170px;
        height: 170px;
        padding: 0.5rem;
        position: relative;
        &--loading {
        }
        &:after {
            content: '';
            position: absolute;
            top: 0.5rem;
            bottom: 0.5rem;
            left: 0.5rem;
            right: 0.5rem;
            margin: auto;
            background: $ebony;
            z-index: 0;
        }
    }
    &__img {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
        padding: 0;
        object-fit: cover;
        object-position: top;
        opacity: 0;
        animation: appear 0.5s ease forwards;
        box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.3);
    }
    .spinner {
        width: 18px;
        height: 18px;
        z-index: 1;
    }
}
