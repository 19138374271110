/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
    font: inherit;
    display: inline-block;
    overflow: visible;
    margin: 0;
    padding: 1.5625rem;
    cursor: pointer;
    transition-timing-function: linear;
    transition-duration: 0.15s;
    transition-property: opacity, filter;
    text-transform: none;
    color: inherit;
    border: 0;
    background-color: transparent;

    &--active:hover,
    &:hover {
        opacity: 0.7;
    }

    &--active .hamburger__inner,
    &--active .hamburger__inner:after,
    &--active .hamburger__inner:before {
        background-color: #fff;
    }

    &__box {
        position: relative;
        display: inline-block;
        width: 1.25rem;
        height: 1.25rem;
    }

    &__inner {
        top: 50%;
        display: block;
        margin-top: -0.125rem;
    }

    &__inner,
    &__inner:after,
    &__inner:before {
        position: absolute;
        width: 1.25rem;
        height: 0.125rem;
        transition-timing-function: ease;
        transition-duration: 0.15s;
        transition-property: transform;
        border-radius: 0.125rem;
        background-color: #fff;
    }

    &__inner:after,
    &__inner:before {
        display: block;
        content: '';
    }

    &__inner:before {
        top: -0.4375rem;
    }

    &__inner:after {
        bottom: -0.4375rem;
    }

    &--spin {
        .hamburger__inner {
            transition-duration: 0.22s;
            transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

            &::before {
                transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
            }

            &::after {
                transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
            }
        }

        &.hamburger--active {
            .hamburger__inner {
                transform: rotate(225deg);
                transition-delay: 0.12s;
                transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

                &::before {
                    top: 0;
                    opacity: 0;
                    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
                }

                &::after {
                    bottom: 0;
                    transform: rotate(-90deg);
                    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
                }
            }
        }
    }
}
