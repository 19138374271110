.create-recipient {
    background-image: radial-gradient(circle at 0 0, $dark-clay, $dark);
    max-height: 90vh;
    overflow: auto;
    padding: 55px 66px 33px;
    display: block;
    position: relative;
    max-width: 960px;
    margin: auto;
    &:after {
        content: '';
        display: table;
        clear: both;
    }
    &__search {
        // We want to show the same element here. Maybe this should be it's own block?
        @extend .history__search;
        top: -55px;
        .button {
            margin-top: auto;
            min-height: 50px;
        }
    }
    &__hits {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 22px;
    }
    &__hits-item {
        display: flex;
        flex-basis: 49%;
        margin-bottom: 2%;
    }
    &__card {
        background: $dark-clay;
        box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.4);
        padding: 1.25rem;
        height: 100%;
        opacity: 0;
        animation: slide-in 0.5s ease forwards;
        transition: background 0.25s ease;
        width: 100%;
        height: 100%;
        display: flex;
        .caption {
            margin-bottom: 0;
        }
        .button {
            margin-bottom: 0;
        }
        &--active {
            background-color: $slate;
        }
    }
    &__card-panel {
        flex-basis: 70%;
        flex-grow: 2;
        &:last-child {
            flex-basis: 90px;
            flex-grow: 0;
            text-align: right;
            align-self: flex-end;
        }
    }
    .close--alt {
        position: absolute;
    }
    .button--approve {
        margin-bottom: 0;
    }
    > .form {
        overflow: hidden;
        position: sticky;
        top: 70px;
        z-index: 3;
        &__async-status {
        }
    }
}
