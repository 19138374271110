.upload-file {
    width: 80%;
    height: 300px;
    margin: 0 auto 3rem;
    display: flex;
    flex-direction: column;
    user-select: none;
    &--dragging {
        .upload-file__text {
            display: none;
            &--dragging {
                display: block;
            }
        }
    }
    &--error {
        animation: shake 1s ease;
        .upload-file__label {
            border: 1px solid $lipstick;
        }
    }
    &__label {
        flex-basis: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        cursor: pointer;
        background: radial-gradient(circle at 0 0, lighten($charcoal-grey, 2%), $charcoal-grey);
        box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.3);
        margin-bottom: 30px;
        padding: 10px;
        &--disabled {
            opacity: 0.5;
            cursor: default;
        }        
    }
    &__text {
        text-align: center;
        padding: 0 0.5rem;
        &--dragging {
            display: none;
        }
        &--error {
            animation: slide-up 0.5s ease forwards;
            opacity: 0;
            margin-top: 0.25rem;
        }
    }
    &__icon {
        margin-bottom: 22px;
    }
    &__input {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
    &__button {
        margin: auto;
    }
}
