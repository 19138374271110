.modal {
    height: 100%;
    width: 100%;
    overflow: visible;
    &__content {
        height: 100%;
        filter: unset;
        overflow: visible;
    }
    &__wrapper {
        width: 100%;
        max-height: 90%;
        max-width: 1440px;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto 70px;
        position: relative;
        pointer-events: none;
        display: flex;
        justify-content: center;
        > * {
            pointer-events: all;
            box-shadow: 0 0 2.5rem 0 rgba(0, 0, 0, 0.6);
        }
    }
    &__close-handler {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 10;
        cursor: pointer;
        top: 0;
        left: 0;
    }
    &__close {
        position: absolute;
        bottom: -70px;
        right: 0;
    }
    &:after {
        content: '';
        transition: background 0.25s ease;
        background: transparent;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        z-index: 9;
    }
    &--open {
        overflow: hidden;
        .modal {
            &__box {
                position: fixed;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                margin: auto;
                z-index: 11;
                display: flex;
                justify-content: center;
                align-items: center;
                pointer-events: none;
            }
            &__content {
                overflow: hidden;
                transition: filter 0.25s ease, background 0.25s ease;
                filter: blur(5px);
            }
        }
        &:after {
            content: '';
            transition: background 0.25s ease;
            background: rgba(black, 0.6);
            pointer-events: all;
        }
        .content__main {
            overflow: hidden;
        }
    }
    .close {
        z-index: 11;
    }
}

@media all and (max-width: 768px) {
    .modal {
        &__wrapper {
            overflow: auto;
            max-width: 95%;
            margin: auto 20px;
            height: calc(100vh - 7.8125rem);
        }
    }
}
