.information-modal {
    max-width: 800px;
    width: 100%;
    margin: auto;
    background: $dark;
    color: white;
    padding: 33px;
    .paragraph,
    .caption,
    .title,
    .link {
        color: white;
    }
    &__panel {
        flex-basis: 31%;
        padding: 1rem 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: initial;
        &:first-child {
            flex-grow: 2;
            padding-left: 1rem;
        }
        &:last-child {
            display: block;
            text-align: right;
            padding-right: 1rem;
        }
        .paragraph,
        .caption {
            margin-bottom: 0;
            font-style: normal;
        }
    }
    .title--h2 {
        position: relative;
        padding-right: 45px;
    }
}

@media all and (max-width: 768px) {
    .information-modal {
        &__panel {
            padding: 0 1rem;
            &:last-child {
                display: flex;
                justify-content: center;
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
            }
        }
    }
}
