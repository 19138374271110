.close {
    display: inline-block;
    overflow: visible;
    margin: 0;
    padding: 1.5625rem;
    cursor: pointer;
    border: 0;
    background-color: transparent;

    &__icon {
        position: relative;
        display: inline-block;
        width: 1.25rem;
        height: 1.25rem;

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 0.125rem;
            background: $dark;
            transform-origin: center;
            margin: auto;
        }
        &:before {
            transform: rotate(-45deg);
        }
        &:after {
            transform: rotate(45deg);
        }
    }
    &--alt {
        position: fixed;
        top: 5px;
        right: 5px;
        z-index: 9;
        .close__icon:before,
        .close__icon:after {
            background: white;
        }
    }
}
