.login {
    display: flex;
    height: 100%;
    &__form {
        flex-basis: 508px;
        max-width: 600px;
        flex-grow: 1;
        box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.4);
        background-image: radial-gradient(circle at 0 0, $dark-clay, $ebony);
        padding: 60px;
    }
    &__logo {
        transition: margin 0.25s ease;
        margin: 28px auto 88px;
        display: block;
        width: 50%;
        max-width: 200px;
    }
    &__graphic {
        flex-grow: 1;
        flex-basis: 892px;
        background-image: url('/img/loginBG.jpg');
        background-size: cover;
        background-position: center center;
    }
    .button {
        margin-bottom: 0;
    }
    .form {
        max-width: 390px;
        width: 100%;
        margin: auto;
        border-bottom: 1px solid $divider;
        padding-bottom: 30px;
        margin-bottom: 30px;
        &:last-child {
            border-bottom: none;
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }
    .versionInfo {
        font-size: 0.5rem;
        align-self: flex-end;
        text-align: center;
    }

    .debug-info, .debug-info h2 {
        background-color: white;
        color: black;
        font-size: 0.7rem;
    }

    .debug-info label {
        padding: 3px;
        display: block;
    }

    .debug-info span {
        font-weight: bold;
    }
}

.login-button-container {
    margin-top: 10px;
}

.login-component-container button {
    @extend .button;
    @extend .button--function;
    @extend .button--full;
}

.login-component-container .qr-code {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.login-component-container .status {
    margin: 10px 0 10px 0;
}

.login-component-container p {
    margin-top: 10px;
}

.login-component-container .qr-code svg {
    box-shadow: 0 0 40px 0 rgba($blue, 0.3);
    margin: 10px;
    width: 220px;
    height: 220px;
}

.login-component-container .qr-code svg::after {
    border: 1px solid $blue;
}

@media all and (max-height: 720px) and (min-width: 1079px) {
    .login {
        &__logo {
            margin: 4vh auto 8vh;
            display: block;
            max-width: 130px;
            margin-top: 0;
        }
        &__form {
            padding: 40px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .form {
            margin: initial initial 1.875rem;
        }
        .button {
            padding: 0.5rem;
            font-size: 0.875rem;
        }
        .input {
            margin-bottom: 1.5rem;
            &__field {
                padding: 0.75rem 0.875rem;
            }
        }
    }
}
@media all and (max-width: 1080px) {
    .login {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px !important;
        &__logo {
            width: 60%;
            display: block;
            margin: 4vh auto 8vh;
        }
        &__form {
            flex-grow: 0;
            width: auto;
            p {
                font-size: 0.875rem;
            }
            .input__label {
                font-size: 0.875rem;
            }
        }
        &__graphic {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
        }
        .button {
            padding: 0.5rem;
            font-size: 0.875rem;
        }
    }
}
@media all and (max-width: 768px) {
    .login {
        padding: 1.875rem;
        &__form {
            padding: 40px;
        }
        &__logo {
            width: 50%;
            display: block;
            margin: 1rem auto 2rem;
        }
    }
}
