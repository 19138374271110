.scrollable {
    display: table;
    width: 100%;
    table-layout: fixed;
    margin-bottom: 1rem;
    &__content {
        padding-bottom: 0.5rem;
        display: table-cell;
        overflow: auto;
    }
    .table {
        min-width: 100%;
        width: auto;
        margin-bottom: 0;
        &__body {
            .table__cell:not(:empty) {
                min-width: 100px;
            }
        }
    }
    ::-webkit-scrollbar-track {
        width: 8px;
        height: 8px;
        border: 2px solid transparent;
        background-color: $scrollTrack;
    }
    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {
        width: 4px;
        height: 4px;
        border-radius: 4px;
        border: 4px solid $scroll;
        background-color: $scroll;
    }
}
