.inbox {
    &__greeting {
        padding: 45px 34px 22px;
    }
    &__list {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    &__item {
        display: flex;
        flex-direction: column;
        padding: 18px 28px;
        border: inherit;
        border: none;
        background-color: $dark;
        appearance: none;
        color: $textColor;
        text-align: inherit;
        min-height: 84px;
        width: 100%;
        transition: background 0.125s ease-in;
        cursor: pointer;
        margin-bottom: 4px;
        &:hover {
            outline: none;
            background-color: rgba($slate, 0.5);
        }
        &:focus {
            outline: none;
            background-color: rgba($slate, 0.5);
        }
        &--active {
            background-color: $slate;
        }
    }
    &__subject {
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 8px;
    }
    &__subject-data {
        margin-right: 0.5rem;
    }
    &__subject-data,
    &__hourglass {
        display: inline-block;
        vertical-align: text-bottom;
    }
    &__date {
        font-size: 14px;
    }
    &__state {
        font-size: 12px;
        margin-bottom: 4px;
    }
    &__date-label {
        font-size: 14px;
    }
    .title--h3 {
        margin-bottom: 0;
    }
}
