.button {
    appearance: none;
    border: 1px solid $meadow;
    font-size: 1rem;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $textColor;
    padding: 0.6875rem 1.375rem;
    background: none;
    margin-bottom: 1rem;
    text-align: center;
    box-shadow: 0 0 0.625rem 0 rgba($meadow, 0.3);
    cursor: pointer;
    &--secondary {
        color: white;
        background: none;
        border: solid 2px white;
    }
    &:link {
        text-decoration: none;
        display: inline-block;
    }
    .icon {
        margin-left: 12px;
        vertical-align: middle;
        height: 1.2em; // 20% more than font size so that line height is used
        &--left,
        &--right {
            width: 1.125rem;
            height: 1.125rem;
        }
        &--left {
            margin-left: 0;
            margin-right: 12px;
        }
    }
    .spinner {
        height: 1.375rem;
        width: 1.375rem;
        display: inline-block;
        right: 1.25rem;
        left: auto;
    }
    &--loading {
        .spinner {
            position: static;
            vertical-align: middle;
            margin-left: 0.75rem;
        }
    }
    &--disabled {
        opacity: 0.5;
        animation: none;
        cursor: default;
        pointer-events: none;
    }
    &--arrow:after {
        content: '';
        background-image: url('/img/svg/src/right.svg');
        background-repeat: no-repeat;
        background-size: 100%;
        vertical-align: text-bottom;
        margin-left: 8px;
        width: 1.125rem;
        height: 1.125rem;
        display: inline-block;
    }
    &--small {
        padding: 0.5rem;
        font-size: 0.875rem;
        .icon {
            width: 1rem;
            height: 1rem;
            margin-left: 0.375rem;
            &--left,
            &--right {
                width: 1rem;
                height: 1rem;
            }
            &--left {
                margin-left: 0;
                margin-right: 0.375rem;
            }
        }
        .spinner {
            width: 1rem;
            height: 1rem;
            margin-left: 0.375rem;
        }
    }
    &--chip {
        font-size: 0.75rem;
        padding: 0.25rem 0.75rem;
        font-weight: 600;
        display: inline-block;
        cursor: default;
    }
    &--approve {
        border: 1px solid $meadow;
        box-shadow: 0 0 10px 0 rgba($meadow, 0.3);
    }
    &--wait {
        border: 1px solid $saffron;
        box-shadow: 0 0 10px 0 rgba($saffron, 0.2); // intentionally lower alpha due to yellow and dark colors contrast ratio
    }
    &--warning {
        border: 1px solid $lipstick;
        box-shadow: 0 0 10px 0 rgba($lipstick, 0.3);
    }
    &--function {
        border: 1px solid $blue;
        box-shadow: 0 0 10px 0 rgba($blue, 0.3);
    }
    &--full {
        width: 100%;
    }
    &--link {
        background: none;
        color: inherit;
        font-weight: inherit;
        padding: inherit;
        margin: inherit;
        border: none;
        box-shadow: none;
        .icon {
            height: 1em;
            margin-left: 4px;
            vertical-align: middle;
        }
    }
    &--right {
        float: right;
    }
    &--icon-only {
        padding: 4px 18px;
        .icon {
            margin: auto;
            display: block;
            height: 1.2em;
            width: 1.2em;
        }
    }
    &--icon {
        display: inline-flex;
        border: none;
        box-shadow: none;
        padding: 10px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        transition: box-shadow 0.25s ease, background 0.25s ease;
        .icon {
            display: inline-block;
            max-width: 100%;
            max-height: 100%;
            margin: auto;
            vertical-align: middle;
        }
        &:hover,
        &:focus {
            background: $charcoal-grey;
            box-shadow: 0 0 25px 5px rgba(0, 0, 0, 0.3);
            transition: box-shadow 0.125s 0.125s ease, background 0.25s ease;
        }
    }
    &--marginleft {
        margin-right: 5px;
    }
}
