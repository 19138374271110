.navigation {
    display: flex;
    &__header {
        display: none;
    }
    &__menu {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: env(safe-area-inset-top);
        height: 100vh;
        flex-basis: 250px;
        flex-shrink: 0;
        box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.6);
        background-image: radial-gradient(circle at 0 0, $shadow, $dark);
        position: sticky;
        top: 0;
        z-index: 2;
    }
    &__logo {
        text-align: center;
        padding: 46px 0;
        min-height: 7.625rem;
        img {
            width: 70%;
            margin: auto;
            max-width: 200px;
        }
    }
    &__logo-small {
        text-align: center;
        min-height: 4rem;
        margin-top: -5px;
        img {
            margin: auto;
            max-width: 130px;
        }
    }
    &__notification {
        position: absolute;
        width: 22px;
        height: 22px;
        font-size: 0.875rem;
        background: $lipstick;
        text-align: center;
        border-radius: 50%;
        left: 0.5rem;
        top: 0.25rem;
        display: table;
        &--child {
            left: 8.5rem;
        }
    }
    &__notification-count {
        display: table-cell;
        vertical-align: middle;
        line-height: 1;
    }
    &__section {
        &--active {
            background-color: $dark-clay;
            .navigation__arrow {
                .icon {
                    transform: rotate(0);
                }
            }
        }
    }
    &__sub-menu {
        margin-bottom: 4px;
        .navigation__item {
            opacity: 0;
            animation: appear 0.5s 0.25s ease forwards;
            font-family: 'Open Sans', sans-serif;
            font-weight: normal;
            font-size: 0.875rem;
            padding: 0.625rem 2.375rem;
            line-height: 1;
            &--active {
                background: $slate;
                font-weight: bold;
            }
        }
    }
    &__item {
        position: relative;
        padding: 0.75rem 1.125rem;
        display: flex;
        align-items: center;
        font-family: 'Poppins', sans-serif;
        font-size: 1rem;
        font-weight: 600;
        &:link,
        &:active,
        &:visited {
            color: $textColor;
            text-decoration: none;
        }
        &--active {
            background-color: $dark-clay;
            font-weight: bold;
            .navigation__icon {
                display: none;
                &--active {
                    display: block;
                }
            }
        }
    }
    &__content {
        flex-grow: 2;
        flex-shrink: 2;
    }
    &__icon {
        margin-right: 0.875rem;
        .icon {
            width: 1.25rem;
        }
        &--active {
            animation: slide-in 0.25s ease;
            display: none;
        }
    }
    &__arrow {
        position: absolute;
        right: 1.125rem;
        .icon {
            width: 1rem;
            height: 1rem;
            transition: 0.125s ease;
            transform-origin: center center;
            transform: rotate(-90deg);
        }
    }
    &__text {
        display: block;
    }
    &__user,
    &__current-commitment {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 0.75rem;
        transition: background 0.25s ease;
    }
    &__current-commitment {
        &:hover {
            cursor: pointer;
            background: $dark-clay;
        }
    }
    &__user {
        .paragraph {
            font-family: 'Poppins', sans-serif;
            font-size: 0.875rem;
            font-weight: 600;
            margin-bottom: 0;
        }
        .icon--settings {
            height: 1rem;
            width: 1rem;
        }
    }
    &__commitment {
        user-select: none;
        position: relative;
        .paragraph,
        .caption {
            font-size: 0.875rem;
            margin-bottom: 0;
        }
        .navigation__current-commitment {
            .icon--right {
                height: 1rem;
                width: 1rem;
                transform-origin: center;
                transform: rotate(90deg);
                transition: transform 0.125s ease;
            }
        }
        .commitment-picker {
            pointer-events: none;
            animation: commitment-leave 0.25s ease;
            transition: all 0.5s;
            opacity: 0;
            position: absolute;
            bottom: 0;
            right: 0;
            box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.4);
            background-image: radial-gradient(circle at 0 0, $dark-clay, $ebony);
            padding: 1.375rem;
            width: 19.375rem;
            z-index: -1;
        }
        &--active {
            .navigation__current-commitment {
                background-image: linear-gradient(to right, $ebony, $dark-clay);
                .icon--right {
                    transform: rotate(0);
                }
            }
            .commitment-picker {
                pointer-events: all;
                animation: commitment-enter 0.25s ease forwards;
                z-index: initial;
            }
        }
    }
    &__logout {
        background-color: $charcoal-grey;
        padding: 0.5rem 0.75rem;
        .button {
            margin-bottom: 0;
        }
    }
}

@media all and (max-width: 1080px) {
    .navigation {
        overflow: visible;
        display: block;
        height: 100%;
        &__header {
            position: sticky;
            top: 0;
            z-index: 2;
            display: block;
            background-image: radial-gradient(circle at 0 0, $shadow, $dark);
            box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.6);
            padding: 1rem 0;
            height: 4.375rem;
            .icon {
                margin: auto;
                display: block;
            }
            .hamburger {
                position: absolute;
                left: 0;
                top: 0;
            }
        }
        &__close-handler {
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1;
            cursor: pointer;
            top: 0;
            left: 0;
            z-index: 8;
            display: none;
        }
        &__menu {
            position: fixed;
            transform: translateX(-100%);
            transition: transform 0.25s ease;
            box-shadow: none;
            width: 70%;
            max-width: 25rem;
            z-index: 9;
            top: 0;
            left: 0;
        }
        &:after {
            content: '';
            transition: background 0.25s 0.25s ease;
            background: transparent;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            z-index: 7;
        }
        &__content {
            height: calc(100% - 4.375rem);
        }
        &__content,
        &__header {
            transition: filter 0.25s ease;
        }
        &__commitment {
            .commitment-picker {
                animation: mobile-commitment-leave 0.25s ease;
                left: 0;
                right: 0;
                bottom: auto;
                width: 100%;
            }
            &--active {
                .commitment-picker {
                    animation: mobile-commitment-enter 0.25s ease forwards;
                }
            }
        }

        &--active {
            .navigation__close-handler {
                display: block;
            }
            .navigation__menu {
                transform: translateX(0);
                transition: transform 0.25s 0.25s ease;
                box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.6);
            }
            .navigation__content,
            .navigation__header {
                filter: blur(5px);
                transition: filter 0.25s ease;
            }
            &:after {
                content: '';
                transition: filter 0.25s ease, background 0.25s ease;
                background: rgba(black, 0.6);
                pointer-events: all;
            }
        }
    }
}
