.bar {
    position: relative;
    @include trackUpperStyles();
    cursor: default;
    &__info {
        font-size: 0.75rem;
        font-style: italic;
        line-height: normal;
        position: absolute;
        top: -1.375rem;
        right: 0;
    }
    &__progress {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        transition: width 0.25s cubic-bezier(0.46, -0.03, 0.12, 1.1);
        @include trackLowerStyles();
        cursor: default;
    }

    &--error {
        .bar__progress {
            box-shadow: 0 0 10px 0 rgba($lipstick, 0.5);
            background-image: linear-gradient(to left, adjust-hue($lipstick, 5deg), $lipstick);
        }
    }
    &--danger {
        .bar__progress {
            box-shadow: 0 0 10px 0 rgba(#e26952, 0.5);
            background-image: linear-gradient(to left, adjust-hue(#e26952, -13deg), #e26952);
        }
    }
    &--warning {
        .bar__progress {
            box-shadow: 0 0 10px 0 rgba($saffron, 0.5);
            background-image: linear-gradient(to left, adjust-hue($saffron, -13deg), $saffron);
        }
    }
    &--concern {
        .bar__progress {
            box-shadow: 0 0 10px 0 rgba(#e1f53e, 0.5);
            background-image: linear-gradient(to left, adjust-hue(#e1f53e, -13deg), #e1f53e);
        }
    }
    &--normal {
        .bar__progress {
            box-shadow: 0 0 10px 0 rgba($blue, 0.5);
            background-image: linear-gradient(to left, adjust-hue($blue, -13deg), $blue);
        }
    }
    &--processing {
        .bar__progress {
            animation: bar-progressing 1s ease infinite;
            box-shadow: 0 0 10px 0 rgba($meadow, 0.5);
            background-image: linear-gradient(to left, adjust-hue($meadow, -13deg), $meadow);
        }
    }
    &--almost {
        .bar__progress {
            box-shadow: 0 0 10px 0 rgba($tealish, 0.5);
            background-image: linear-gradient(to left, adjust-hue($tealish, -13deg), $tealish);
        }
    }

    &--has-info {
        margin-top: 18px;
    }
}
