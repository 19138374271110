.table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    margin: 0 0 2rem;
    &__head {
        .table__cell {
            font-size: 12px;
            border-bottom: 1px solid $divider;
            padding-bottom: 0.4375rem;
        }
    }
    &__body {
    }
    &__row {
        &--clickable {
            cursor: pointer;
            &:hover .table__cell {
                background: darken($message, 2%);
            }
            .table__cell {
                transition: 0.25s ease;
            }
        }
    }
    &__cell {
        text-align: left;
        font-weight: normal;
        color: $textColor;
        font-size: 14px;
        font-weight: normal;
        padding: 10px 4px;
        &--data {
            text-align: right;
        }
        &--relative {
            position: relative;
        }
        .spinner {
            display: inline-block;
            position: relative;
            width: 22px;
            height: 22px;
        }
    }
    .icon--right {
        width: calc(1em + 2px);
        height: calc(1em + 2px);
    }

    &--scrollable {
        width: auto;
        overflow: auto;
    }
}

@media all and (max-width: 768px) {
    .table {
        &__cell {
            word-break: break-word;
            &--hidden-mobile {
                display: none;
            }
        }
    }
}
